export enum ActionTypeEnum {
    SET_EDIT_MODE = "SET_EDIT_MODE",
    RESET_EDIT_MODE = "RESET_EDIT_MODE",
  }
  
  export enum ReviewFieldEnum {
    TITLE = "title",
    NAME = "name",
    DATE_OF_BIRTH = "dateofbirth",
  }
  
  export enum ReviewNameFieldEnum {
    FIRST_NAME = "firstname",
    MIDDLE_NAME = "middlename",
    LAST_NAME = "lastname",
  }
  