import React, { useEffect, useState } from "react";
import { StepperChoosePlan } from "../../../screens/choosePlan/stepperChoosePlan";
import { StepperCitizenShipNdTaxInfromation } from "../../../screens/choosePlan/stepperCitizenShipNdTaxInfromation";
import { StepperPersonalInformation } from "../../../screens/personalInformation/stepperPersonalInformation";
import StepperSummaryBox from "../../../screens/summaryBox/stepperSummaryBox";
import { StepperButton } from "./stepperButton";
import { StepperFooter } from "./stepperFooter";
import { StepperTitle } from "./stepperTitle";
import StepperFscsAndTerms from "../../../screens/submissionAndVerification/stepperFscsAndTerms";
import StepperLinkBankAccount from "../../../screens/linkBankAccount/stepperLinkBankAccount";
import StepperEmploymentDetails from "../../../screens/employmentDetails/stepperEmploymentDetails";
import { clearStates } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../../redux/redux-hooks";
import StepperReviewComponent from "../../../screens/choosePlan/ReviewStepper/stepperReviewComponent";

export const StepperRunner = () => {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(()=>{
    dispatch<any>(clearStates());
  },[])

  return (
    <>
      <div className="container-xxl">
        <StepperTitle {...{ activeStep }} />
        <StepperButton {...{ activeStep }} />
      
        {activeStep === 0 && <StepperChoosePlan />}

        {activeStep === 1 && <StepperSummaryBox />}

        {activeStep === 2 && <StepperPersonalInformation />}

        {activeStep === 3 && <StepperCitizenShipNdTaxInfromation />}

        {activeStep === 4 && <StepperLinkBankAccount />}

        {activeStep === 5 && <StepperReviewComponent/>}

        {activeStep === 6 && <StepperEmploymentDetails />}

        {activeStep === 7 && <StepperFscsAndTerms />}
      </div>

      <div className="container-xxl">
        <StepperFooter {...{ activeStep, setActiveStep }} />
      </div>
    </>
  );
};
