import { useMemo } from "react";
import GenericViewFormComponent from "./GenericViewFormComponent";
import { useAppSelector } from "../../../../redux/redux-hooks";
import Strings from "../../../../theme/string";
import { RootState } from "../../../../interfaces/reviewStepper.interface";
import styles from "../../../../styleModules/reviewStepper.module.scss";

const ProductDetailsViewComponent = () => {
  const { productName, interestRate, aerField } = useAppSelector(
    (state: RootState) => state.stepperDetails.stepperChoosedPlan
  );

  const productDetailsReadData = useMemo(
    () => [
      {
        title: `${Strings.productDetailsProductHeading}`,
        fieldData: `${productName}`,
      },
      {
        title: `${Strings.productDetailsInterestHeading}`,
        fieldData: `${interestRate} % ${Strings.reviewPageGrossText} ${
          aerField ? `% ${aerField}` : ``
        }`,
      },
    ],
    [productName, interestRate, aerField]
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center gap-3 mb-3">
          <div className={styles.informationTitleHeading}>
            {Strings?.productDetailsTitle}
          </div>
        </div>
        <GenericViewFormComponent formData={productDetailsReadData} />
      </div>
    </>
  );
};

export default ProductDetailsViewComponent;
