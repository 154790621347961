import React from "react";
import { useParams } from "react-router-dom";

export const StepperTitle = ({ activeStep, firstText } : any) => {
  const { id } = useParams();
  const title = [
    " - Choose Plan",
    " - Summary Box & Privacy Information",
    " - Personal Information",
    " - Citizenship & Tax Information",
    " - Link your UK bank account.",
    " - Review Details",
    " - Employment Details",
    " - FSCS Protection and Terms & Conditions",
  ];
  const disTitle = title[activeStep].split("-")
  return (
    <div className="Stepper-title">
      <h3><b>{id} - </b>{disTitle[1]}</h3>
    </div>
  );
};
