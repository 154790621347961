import { useState } from "react";
import { updateUserDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../redux/redux-hooks";
import { getAddressBasedOnID, getFindAddress } from "../../services/appApi";
import Strings from "../../theme/string";
import { Button } from "../basic components/button";
import { Input } from "../basic components/input";

const INPUT_TIMEOUT = 250; //ms - It's our input delay
export const SuggestionInput = (props: any) => {
  const dispatch = useAppDispatch();
  const {
    onFocus,
    onBlur,
    placeHolder,
    type,
    id,
    lableValue,
    bottomText,
    spanclassName,
    labelDangerRequired,
    isBottomText,
    isButton,
    buttonText,
    spanVal,
    setSpanVal,
    value,
    isError,
    errorMsg,
    blankErrorMsg
  } = props;
  const [predictions, setPredictions] = useState<any>([]);

  const apiHandler = async (id : string) => {
    let apiData = await getFindAddress(id);
    if (apiData) {
      const predictions = getPredictions([...apiData], id);
      setPredictions([...predictions]);
    } else {
      console.error("Error fetching find address!");
    }
  };

  const toggleSpanVal = () => {
    if (spanVal == Strings.addItManually) setSpanVal(Strings.searchFromDataBase);
    else setSpanVal(Strings.addItManually);
  };

  const getPredictions = (findAddress : [], value: string) => {
    return findAddress.filter(
      (item: any) =>
        item?.locationsummary
    );
  };

  const dispatching = (fldName : string, tmpVal : string, validation : boolean) => {
    dispatch<any>(
      updateUserDetails({
        fieldName: fldName,
        value: tmpVal,
        isValidate: validation,
      })
    );
  }  

  const onChangeHandler = (e: any) => {
    const tmpVal = e.target.value;
    if (!tmpVal) setPredictions([]);
    else if(tmpVal.length >= 3 && spanVal == Strings.addItManually)
    {
      apiHandler(tmpVal)
    }
    dispatching("addressline1",tmpVal, tmpVal ? true : false);
  };

  const updateDependentValue = async (item: string) => {
    let apiData = await getAddressBasedOnID(item);
    setPredictions([]);
    if (apiData) {
      const obj = apiData[0];
      dispatching("addressline1", obj["addressline1"] ? obj["addressline1"] : "" , true );
      dispatching("addressline2", obj["addressline2"] ? obj["addressline2"] : "", true );
      dispatching("addressline3", obj["addressline3"] ? obj["addressline3"] : "", true );
      dispatching("addressline4", obj["addressline4"] ? obj["addressline4"] : "", true );
      dispatching("posttown", obj["posttown"] ? obj["posttown"] : "", true );
      dispatching("postcode", obj["postcode"] ? obj["postcode"] : "", true );
    } else {
      console.error("Error while finding complete address!");
    }
  };

  const clearSetValue = () =>{
    setPredictions([]);
    dispatching("addressline1", "" , false );
    dispatching("addressline2", "", false );
    dispatching("addressline3", "", false );
    dispatching("addressline4", "", false );
    dispatching("posttown", "", false );
    dispatching("postcode", "", false );
  }
  

  return (
    <div className={"col-md-8 col-lg-6"}>
      <label htmlFor={id} className="form-label">
        {lableValue}
        {labelDangerRequired && <span className="text-danger"> * </span>}
      </label>
      <div className="input-group">
        <Input
          type={type}
          className="form-control"
          id={id}
          placeholder={placeHolder}
          value={value}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e)}
          onFocus={clearSetValue}
          onBlur={onBlur}
        />
        {isButton && (
          <Button
            className="btn btn-primary_one border-radius-0"
            disabled={!value || spanVal != "Add it manually"}
            label={buttonText}
          />
        )}
      </div>
      {value && predictions.length > 0 && (
        <div className="suggestion-box w-100">
          {predictions?.map((item: any, index: number) => (
            <div
              key={index + item?.locationsummary}
              onClick={() => updateDependentValue(item.id)}
              className="w-100"
            >
              {item?.summaryline + ", " +item.locationsummary}
            </div>
          ))}
        </div>
      )}
      {isError && (
        <div className="text-danger">{value ? errorMsg : blankErrorMsg}</div>
      )}
      {isBottomText && (
        <div className={spanclassName}>
          {bottomText}
          <span className="cursor-pointer" onClick={() => toggleSpanVal()}>
            <u>{spanVal}</u>
          </span>
        </div>
      )}
    </div>
  );
};
