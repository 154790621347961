import React, { useState, useCallback, useMemo } from "react";
import timer from "../../../assest/timer.svg";
import infoIcon from "../../../assest/infoIcon.svg";
import { Img } from "../../../common/basic components/img";
import { HowWeUseDataModal } from "../../summaryBox/howWeUseDataModal";
import styles from "../../../styleModules/reviewStepper.module.scss";
import commonHeadingStyles from "../../../styleModules/commonHeading.module.scss";
import { useAppSelector } from "../../../redux/redux-hooks";
import Strings from "../../../theme/string";
import {
  ReviewData,
  RootState,
  TimerInfoSectionProps,
} from "../../../interfaces/reviewStepper.interface";

const TimerInfoSection = React.memo(
  ({ timerInfoSection }: { timerInfoSection?: TimerInfoSectionProps }) => {
    const [popupFlag, setPopupFlag] = useState<boolean>(false);
    const togglePopup = useCallback(
      () => setPopupFlag(!popupFlag),
      [popupFlag]
    );
    const { productName, interestRate } = useAppSelector(
      (state: RootState) => state.stepperDetails.stepperChoosedPlan
    );

    const modalData = useMemo(
      () => ({
        modalHeading: Strings?.reviewModalHeading,
        modalDescription: Strings?.reviewModalDescription,
      }),
      []
    );
    return (
      <>
        {popupFlag && (
          <HowWeUseDataModal
            show={true}
            onClick={togglePopup}
            data={timerInfoSection?.modalData}
          />
        )}
        <div className={styles.container}>
          <div className="d-flex my-4 mx-5 align-items-center gap-5">
            <Img
              src={timer}
              className="img-fluid img-margin-top-75 stepper-img"
              alt="Saving accounts"
              width="141px"
              height="141px"
            />
            <article className="d-flex flex-column gap-3">
              <div className={commonHeadingStyles.heading}>
                {productName} {"is nearly ready."}
              </div>
              <div className={styles.subHeading}>
                {timerInfoSection?.subHeading}
              </div>
              <div
                className="d-flex justify-content-start gap-3"
                onClick={togglePopup}
              >
                <Img
                  src={infoIcon}
                  className={`img-fluid ${styles.imageStyle}`}
                  alt="Info icon"
                  width="16px"
                  height="16px"
                  aria-label="Info icon"
                />
                <a
                  href="#"
                  className={styles.infoHeading}
                  aria-label="More information"
                >
                  {Strings.timerInfoSectionInfoHeading}
                </a>
              </div>
              <div className={styles.baseHeadingOuterBox}>
                <div className={styles.baseHeading}>
                  {Strings.timerInfoSectionRatePrefix} {interestRate}
                  {`% ${Strings.reviewPageGrossText}`}
                </div>
              </div>
            </article>
          </div>
        </div>
      </>
    );
  }
);
export default TimerInfoSection;
