const Strings = {
  selectCategoryTitle: "Tell us about your tax information.",
  selectIsCountryUK: {
    title: "Citizenship/Nationality of any other Country, apart from UK",
    radioButton: ["Yes", "No, UK Only"],
  },
  selectOtherCountry: "Mention your nationalities or Citizenship ",
  selectTaxCountry : "Other countries you are Tax resident of",
  selectTaxRecidency: {
    title: "Tax Information, select all that apply.",
    checkInputs: [
      {"country" : "uk", "label" : "I am a UK tax resident","checked":"isTaxInputEnableForUK"},
      {"country" : "another", "label" : "I am also a tax resident of another country","checked":"isTaxInputEnableForAnother"},
      {"country" : "us", "label" : "I am subject to tax in the US ","checked":"isTaxInputEnableForUS"}
    ],
  },
};
export default Strings;
