import { FunctionComponent, useEffect, useState } from "react";
import { getFscsProtectionAndTermsData } from "../../services/appApi";
import { Checkbox } from "../../common/basic components/checkBox";
import { useParams } from "react-router-dom";

interface TermsData {
    fscsData: {
        fscsTitle: string;
        fscsSummary: string;
        fscsInformationSheet: string;
        fscsInformationSheetUrl: string;
        fscsConsentData: string;
    },
    termsData: {
        termsTitle: string;
        termsSummary: string;
        termsGeneralSavings: string;
        termsGeneralSavingsUrl: string;
        termsProductType: string;
        termsProductTypeUrl: string;
        termsSummaryBox: string;
        termsSummaryBoxUrl: string;
        termsConsentData: string;
    }
}

const StepperFscsAndTerms = () => {
    const { id } = useParams();
    const [termsData, setTermsData] = useState<TermsData>();
    const [fscsConsent, setFscsConsent] = useState<boolean>(false);
    const [termsAndConditionsConsent, setTermsAndConditionsConsent] = useState<boolean>(false);

    useEffect(() => {
        apiHandler();
    }, []);

    const apiHandler = async () => {
        let apiData = getFscsProtectionAndTermsData();
        if (apiData) {
            setTermsData(apiData);
        } else {
            console.error("Error fetching fscs abd terms data!");
        }
    };

    return (
        <>
            <div className="fscsAndTermsSec1">
                <div className="row font-size-18px">
                    <div className="col fw-bold">{termsData?.fscsData?.fscsTitle}</div>
                </div>
                <div className="row">
                    <div className="col">
                    <p className="new-line lh-base">{termsData?.fscsData?.fscsSummary}</p>
                    </div>
                </div>
                <LinkRenderer textParam={termsData?.fscsData?.fscsInformationSheet} linkParam={termsData?.fscsData?.fscsInformationSheetUrl} />

                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        <div className="terms-conditions-choosePlan consent-choosePlan">
                            <Checkbox
                                onChange={() => setFscsConsent(!fscsConsent)}
                                label={termsData?.fscsData?.fscsConsentData}
                                inputClassName={"checkbox-width-zero"}
                                labelClassName={fscsConsent && "text-primary_one"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="fscsAndTermsSec2 mt-4">
                <div className="row font-size-18px">
                    <div className="fw-bold">{termsData?.termsData?.termsTitle}</div>
                </div>
                <div className="row">
                    <div className="col">
                        <p className="new-line lh-base">{termsData?.termsData?.termsSummary}</p>
                    </div>
                </div>
                <LinkRenderer textParam={termsData?.termsData?.termsGeneralSavings} linkParam={termsData?.termsData?.termsGeneralSavingsUrl} />
                <LinkRenderer textParam={id} linkParam={termsData?.termsData?.termsProductTypeUrl} />
                <LinkRenderer textParam={termsData?.termsData?.termsSummaryBox} linkParam={termsData?.termsData?.termsSummaryBoxUrl} />
                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        <div className="terms-conditions-choosePlan consent-choosePlan">
                            <Checkbox
                                onChange={() => setTermsAndConditionsConsent(!termsAndConditionsConsent)}
                                label={termsData?.termsData?.termsConsentData}
                                inputClassName={"checkbox-width-zero"}
                                labelClassName={fscsConsent && "text-primary_one"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StepperFscsAndTerms;

interface LinkRendererProps {
    textParam?: string;
    linkParam?: string;
}

const LinkRenderer: FunctionComponent<LinkRendererProps> = ({ textParam, linkParam }) => {
    return (<div className="row">
        <div className="col-md-8 col-lg-6">
            <div
                className="terms-conditions-choosePlan openLinkItems"
                role="button"
                onClick={() => window.open(linkParam)}>
                <span>{textParam}</span>
                <span>
                    <img
                        alt={'Right Arrow Icon'}
                        src={require(`../../assest/OpenLinkRightChevron.png`)}
                        className="open-link-right-rotated-icon"
                    />
                </span>
            </div>
        </div>
    </div>)
}
