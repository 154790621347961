import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MemorableSectionState } from "../../interfaces/memorableSection.interface";

const initialState: MemorableSectionState = {
  memorableWord: "",
  hint: "",
  memorableWordErrorFlag: false,
  key:undefined,
};

const memorableWordSlice = createSlice({
  name: "memorableWord",
  initialState,
  reducers: {
    setMemorableWordAndHint: (
      state,
      action: PayloadAction<{ memorableWord: string; hint: string ,key:CryptoKey}>
    ) => {
      state.memorableWord = action.payload.memorableWord;
      state.hint = action.payload.hint;
      state.key=action.payload.key;
    },
    resetMemorableWordAndHint: (state) => {
      state.memorableWord = "";
      state.hint = "";
      state.key=undefined;
      state.memorableWordErrorFlag = false;
    },
  },
});
export const { setMemorableWordAndHint, resetMemorableWordAndHint } =
  memorableWordSlice.actions;
export default memorableWordSlice.reducer;
