import React from "react";
import { StepperRunner } from "./stepperRunner";
import { useParams } from "react-router-dom";
import MemorableWord from "../../../screens/memorableWord/memorableWord";
import { MEMORABLE_WORD_ROUTE } from "../../constant/customConstant";

export const Stepper = () => {
  const { id } = useParams();
  return (
    <>
      <div>
        <StepperRunner />
      </div>
    </>
  );
};
