import { useEffect, useState } from "react";
import { getVerifyCustomerData } from "../../services/appApi";
import { useNavigate } from "react-router-dom";
import { Img } from "../../common/basic components/img";
import Checklist from "../../assest/Checklist.svg";
import VerificationSuccess from "../../assest/verificationSuccess.svg";
import VerificationWaiting from "../../assest/verificationWaiting.svg";
import { ApplicationSubmissionContent } from "../../interfaces/applicationSubmission.interface";

const VerifyCustomerDetails = () => {
    const navigate = useNavigate();
    const [verifyDataState, setVerifyDataState] = useState<ApplicationSubmissionContent>();
    const [verifying, setVerifying] = useState(true);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        apiHandler(true);
    }, []);

    const apiHandler = async (statusParam:boolean) => {
        try {
            let apiData = await getVerifyCustomerData();
            //Below if else case to be updated with party creation & savings account SF API in next sprint
            //Below APIs to be called for verification cases:
            // 1.	Generate TSCognito Token using API (Hit Create party)
            // 2.	Generate TSToken using API (Hit Create Saving applications)
            // 3.	Generate SFToken using API (Hit Create Saving account api)

            if (apiData && statusParam) {
                setVerifyDataState(apiData);
                await delay(7000);
                setVerificationSuccess(true);
                setVerifying(false);
                navigationHandler();
            } else {
                console.error("Error fetching verify screen CMS data!");
                setVerifyDataState(apiData);
                await delay(7000);
                setVerificationSuccess(false);
                setVerifying(false);
                navigationHandler();
            }
        } catch (error) {
            console.error("Error during API call", error);
        }
    };

    const navigationHandler = async () => {
        await delay(5000);
        navigate("/memorableWord");
    }

    const getContentData = () => {
        if (verifying && !verificationSuccess) {
            return verifyDataState?.verifyData;
        } else if (!verifying && verificationSuccess) {
            return verifyDataState?.verificationSuccessData;
        } else if (!verifying && !verificationSuccess) {
            return verifyDataState?.verificationFailureData;
        }
    }

    return (
        <section className="container-xxl mt-5 customWidth921">
            <div className="terms-conditions-choosePlan consent-choosePlan p-5">
                <div className="text-center">
                    <div>
                        {verifying && !verificationSuccess && <Img
                            src={Checklist}
                            alt="Check List Icon"
                            aria-label="Check List Icon"
                        />}
                        {!verifying && verificationSuccess && <Img
                            src={VerificationSuccess}
                            alt="Verification Success Icon"
                            aria-label="Verification Success Icon"
                        />}
                        {!verifying && !verificationSuccess && <Img
                            src={VerificationWaiting}
                            alt="Verification Pending Icon"
                            aria-label="Verification Pending Icon"
                        />}
                    </div>
                    <div className="row font-size-36px">
                        <div className="col fw-bold">{getContentData()?.title}</div>
                    </div>
                    <div className="row font-size-24px">
                        <div className="col">{getContentData()?.description}</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VerifyCustomerDetails;
