import React from "react";
import styles from "../../../../../styleModules/reviewStepper.module.scss";
import saveIcon from "../../../../../assest/saveIcon.svg";
import { Img } from "../../../../../common/basic components/img";

export const SaveButtonComponent = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.imageStyle} onClick={onClick}>
    <span
      className={`square-stepper-button current ${styles.imageStyle}`}
      style={{ width: "32px", height: "32px", cursor: "pointer" }}
      aria-label="Save form field"
    >
      <Img
        src={saveIcon}
        className="img-fluid img-margin-top-75 stepper-img"
        alt="Save"
        width="16px"
        height="16px"
      />
    </span>
  </div>
);
