import React, { useEffect, useMemo, useState } from "react";
import { SelectInput } from "../../common/basic components/selectInput";
import { CustomModal } from "../../common/components/customModal";
import { DateInput } from "../../common/components/dateInput";
import OTPInput from "../../common/components/otpInput";
import { EmailValidationPopup } from "../../common/components/stepper/emailValidationPopup";
import { SuggestionInput } from "../../common/components/suggestionInput";
import { EIGHTEEN_YEARS_IN_SEC } from "../../common/constant/customConstant";
import { dateFormat } from "../../common/modules/date";
import { updateUserDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import { ValidationInput } from "./validationInput";
import DesignationField from "./personalInformationFields/designationField";
import NameField from "./personalInformationFields/nameField";
import DobField from "./personalInformationFields/dobField";

export const StepperPersonalInformation = (props: any) => {
  const dispatch = useAppDispatch();
  const [spanVal, setSpanVal] = useState(Strings.addItManually);
  const [enableEmailValidationPopup, setEnableEmailValidationPopup] =
    useState(false);
  const [isEmailHasConfirmed, setIsEmailHasConfirmed] = useState(false);
  const [enableMobileOtpPopup, setEnableMobileOtpPopup] = useState(false);
  const [isMobileNumberConfirmed, setIsMobileNumberConfirmed] = useState(false);

  const myDate = new Date();
  const tmpMyDate = myDate.setTime(
    new Date().getTime() - EIGHTEEN_YEARS_IN_SEC
  );
  const userMaxAge = dateFormat(new Date(tmpMyDate), "-", "YYYYMMDD");
  const stepperDetails: any = useAppSelector(
    (state: any) => state.stepperDetails
  );
  const data = stepperDetails?.stepperUserDetails;

  const updateState = (obj: any) => {
    dispatch<any>(updateUserDetails(obj));
  };

  useEffect(() => {
    if (!data["emailaddress"]?.isValidate) setIsEmailHasConfirmed(false);
  }, [data["emailaddress"]?.isValidate]);

  const emailEditHandler = () => {
    setEnableEmailValidationPopup(!enableEmailValidationPopup);
    setIsEmailHasConfirmed(false);
    updateState({
      fieldName: "emailaddress",
      value: "",
      isValidate: false,
    });
  };

  const editMobileNumberHandler = () => {
    setEnableMobileOtpPopup(!enableMobileOtpPopup);
    setIsEmailHasConfirmed(false);
    updateState({
      fieldName: "mobilenumber",
      value: "",
      isValidate: false,
    });
  };

  const emailConfirmationHandler = () => {
    setEnableEmailValidationPopup(!enableEmailValidationPopup);
    setIsEmailHasConfirmed(true);
  };

  const emailButtonValidationHandler = () => {
    if (!data["emailaddress"]?.isValidate) return true;
    else if (data["emailaddress"]?.isValidate && isEmailHasConfirmed)
      return true;
    else return false;
  };

  const titleJsx = useMemo(
    () => (
      <div className="row mb-3">
      <div className="col-6">
          <DesignationField stepperDetails={stepperDetails} reviewFlag={false}/>
        </div>
      </div>
    ),
    [data["title"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const firstNameJsx = useMemo(
    () => (
      <div className="row mb-3">
        <NameField stepperDetails={stepperDetails} fieldName="firstname"/>
      </div>
    ),
    [data["firstname"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const middleNameJsx = useMemo(
    () => (
      <div className="row mb-3">
        <NameField stepperDetails={stepperDetails} fieldName="middlename"/>
      </div>
    ),
    [data["middlename"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const lastNameJsx = useMemo(
    () => (
      <div className="row mb-3">
      <NameField stepperDetails={stepperDetails} fieldName="lastname"/>
      </div>
    ),
    [data["lastname"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const dataOfBirthJsx = useMemo(
    () => (
      <div className="row mb-3">
        <DobField stepperDetails={stepperDetails} reviewFlag={false}/>
      </div>
    ),
    [data["dateofbirth"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const emailAddressJsx = 
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.emailAddress}
          labelDangerRequired={true}
          placeHolder={Strings.emailAddressPlaceholder}
          isBottomText={true}
          isButton={true}
          bottomText={Strings.emailAddressBottomText}
          type={Strings.defaultInputType}
          errorMsg={Strings.emailAddressErrorMsg}
          regExp={Strings.emailAddressRegex}
          buttonValidate={emailButtonValidationHandler()}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["emailaddress"]?.isValidate
          }
          id="emailaddress"
          value={data["emailaddress"]?.value}
          blankErrorMsg={Strings.emailAddressBlankErrorMsg}
          buttonText={Strings.emailAddressButtonText}
          spanclassName="fst-italic font-size-12px"
          confirmationClick={() =>
            setEnableEmailValidationPopup(!enableEmailValidationPopup)
          }
          isConfirm={data["emailaddress"]?.isValidate && isEmailHasConfirmed}
        />
      </div>;

  const mobileNumberJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.mobileNumber}
          labelDangerRequired={true}
          placeHolder={Strings.mobileNumberPlaceholder}
          isBottomText={true}
          isButton={true}
          bottomText={Strings.mobileNumberBottomText}
          regExp={Strings.mobileNumberRegex}
          type={Strings.defaultInputType}
          errorMsg={Strings.mobileNumberErrorMsg}
          buttonValidate={!data["mobilenumber"]?.isValidate}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["mobilenumber"]?.isValidate
          }
          confirmationClick={() =>{
            setEnableMobileOtpPopup(!enableMobileOtpPopup)
            setIsMobileNumberConfirmed(true);}
          }
          isConfirm={data["mobilenumber"]?.isValidate && isMobileNumberConfirmed}
          id="mobilenumber"
          value={data["mobilenumber"]?.value}
          blankErrorMsg={Strings.defaultFieldCanNotBeBlank}
          buttonText={Strings.mobileNumberButtonText}
          spanclassName="fst-italic font-size-12px"
          isPrefixRequired={true}
          prefixeValue="+44"
        />
      </div>
    ),
    [data["mobilenumber"]?.value, stepperDetails.buttonValidatonIsRequired]
  );

  const addressLineOneJsx = useMemo(
    () => (
      <div className="row mb-3">
        <SuggestionInput
          lableValue={Strings.postalAddress}
          labelDangerRequired={true}
          placeHolder={Strings.postalAddressPlaceholder}
          isBottomText={true}
          bottomText={Strings.postalAddressBottomText}
          type={Strings.defaultInputType}
          errorMsg={Strings.postalAddressErrorMsg}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["addressline1"]?.isValidate
          }
          id="addressline1"
          value={data["addressline1"]?.value}
          blankErrorMsg={Strings.defaultFieldCanNotBeBlank}
          spanclassName="fst-italic font-size-12px"
          {...{ spanVal, setSpanVal }}
        />
      </div>
    ),
    [data["addressline1"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  const addressLineTwoJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.addressLine2}
          placeHolder={Strings.addressLine2Placeholder}
          isBottomText={false}
          id="addressline2"
          value={data["addressline2"]?.value}
          spanclassName="fst-italic font-size-12px"
          disabled={spanVal == Strings.addItManually}
        />
      </div>
    ),
    [data["addressline2"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  const addressLineThreeJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.addressLine3}
          placeHolder={Strings.addressLine3Placeholder}
          isBottomText={false}
          id="addressline3"
          value={data["addressline3"]?.value}
          spanclassName="fst-italic font-size-12px"
          disabled={spanVal == Strings.addItManually}
        />
      </div>
    ),
    [data["addressline3"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  const addressLineFourJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.addressLine4}
          placeHolder={Strings.addressLine4Placeholder}
          isBottomText={false}
          id="addressline4"
          value={data["addressline4"]?.value}
          spanclassName="fst-italic font-size-12px"
          disabled={spanVal == Strings.addItManually}
        />
      </div>
    ),
    [data["addressline4"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  const postTownJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.townCity}
          labelDangerRequired={true}
          placeHolder={Strings.townCityPlaceholder}
          isBottomText={false}
          errorMsg={Strings.townCityErrorMsg}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["posttown"]?.isValidate
          }
          id="posttown"
          value={data["posttown"]?.value}
          reviewFlag={false}
          blankErrorMsg={Strings.townCityBlankErrorMsg}
          spanclassName="fst-italic font-size-12px"
          disabled={spanVal == Strings.addItManually}
          regExp={Strings.postalCodeRegex}
        />
      </div>
    ),
    [data["posttown"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  const postCodeJsx = useMemo(
    () => (
      <div className="row mb-3">
        <ValidationInput
          lableValue={Strings.postalCode}
          labelDangerRequired={true}
          placeHolder={Strings.postalCodePlaceholder}
          isBottomText={false}
          type={Strings.defaultInputType}
          id="postcode"
          reviewFlag={false}
          value={data["postcode"]?.value}
          isError={
            stepperDetails.buttonValidatonIsRequired &&
            !data["postcode"]?.isValidate
          }
          regExp={Strings.postalCodeRegex}
          errorMsg={Strings.postalCodeErrorMsg}
          blankErrorMsg={Strings.postalCodeBlankErrorMsg}
          spanclassName="fst-italic font-size-12px"
          disabled={spanVal == Strings.addItManually}
        />
      </div>
    ),
    [data["postcode"]?.value, spanVal, stepperDetails.buttonValidatonIsRequired]
  );

  return (
    <>
      {enableEmailValidationPopup && (
        <CustomModal
          show={enableEmailValidationPopup}
          title={"Email Confirmation"}
          onClick={() =>
            setEnableEmailValidationPopup(!enableEmailValidationPopup)
          }
          child={<EmailValidationPopup email={data["emailaddress"]?.value}/>}
          buttonVal={[
            {
              value: "Edit",
              className: "btn btn-md btn-primary_one-outlined me-2",
              onClick: () => emailEditHandler(),
              size: "sm",
            },
            {
              value: "Confirm",
              className: "btn btn-md btn-primary_one",
              onClick: () => emailConfirmationHandler(),
              size: "sm",
            },
          ]}
          isButton={true}
          size="md"
        />
      )}
      {enableMobileOtpPopup && (
        <CustomModal
          show={enableMobileOtpPopup}
          title={"We’ve sent you verification code"}
          onClick={() => setEnableMobileOtpPopup(!enableMobileOtpPopup)}
          child={
            <OTPInput
              mobileNumber={data["mobilenumber"]?.value}
              onClick={editMobileNumberHandler}
            />
          }
          buttonVal={[
            {
              value: "Submit",
              className: "btn btn-md btn-primary_one",
              onClick: () => setEnableMobileOtpPopup(false),
              size: "sm",
            },
          ]}
          isButton={true}
          size="md"
        />
      )}
      <div className="wizard_taxcitizenship">
        <div className="row mb-3">
          <div className="col">{Strings.stepperPersonalInformationTitle}</div>
        </div>

        {titleJsx}
        {firstNameJsx}
        {middleNameJsx}
        {lastNameJsx}
        {dataOfBirthJsx}
        {emailAddressJsx}
        {mobileNumberJsx}
        {addressLineOneJsx}
        {addressLineTwoJsx}
        {addressLineThreeJsx}
        {addressLineFourJsx}
        {postTownJsx}
        {postCodeJsx}
      </div>
    </>
  );
};
