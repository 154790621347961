const Strings = {
  selectCategoryTitle: "Savings Account",
  selectCategoryDescription:
    "We know your are busy. so we keep things simple and of course , your savings are eligible for FCSC Protection.  Open a Fixed Term Deposits to lock your funds into higher rate, or choose an Easy Access Savings account for flexibility.",
  chooseAnAccountBtn: "Choose an Account",
  btnCancel: "Cancel",
  btnPrevios: "Previous",
  btnContinue: "Continue",
  startApplication: "Start Application",
  submitApplication: "Submit Application",
  choosePlanSummaryHeader:
    "Choose the product that best fits your needs based on the interest rate and terms.",
  typeToSearch: "Type to search",
  enterTextNumber: "Enter Tax Number",
  taxNumberFor: "Tax Number for",
  taxFormatExample: "e.g. 000000000 9 digit format",
  stringNotConsidered: "Only numeric is required.",
  reachedAtMaxLength: "Maximum length is reached.",
  interestPaidOption1: "This",
  interestRadioLinkedAccount: "Linked Account",
  linkBankInterestRadioLabel: "Choose an option for interest being paid",
  linkBankSortCodeLabel: "Sort Code",
  linkBankSortCodePlaceholder: "Enter Sort Code",
  linkBankSortCodeHelperText: "e.g.  000000 sort code should be 6 digits",
  linkBankSortCodeError: "Please enter correct sort Code.",
  linkBankSortCodeBlankError: "Please enter your sort code",
  linkBankAccountNumberLabel: "Account Number",
  linkBankAccountNumberPlaceholder: "Enter Account Number",
  linkBankAccountNumberHelperText:
    "e.g.  00000000 Account number should be 8 digits",
  linkBankAccountNumberError: "Please enter correct Account number.",
  linkBankAccountNumberBlankError: "Please enter your Account Number",
  linkBankAmountDepositLabel: "How much are you planning to deposit?",
  linkBankAmountDepositPlaceholder: "Enter Amount",
  example: "e.g. ",
  linkBankAmountDepositHelperText: ", This is your estimated deposit amount",
  linkBankAmountDepositError: "Please enter an amount greater than £0.",

  firstName: "First Name",
  firstNamePlaceholder: "Enter First Name",
  firstNameBlankErrorMsg: "Please enter your first name.",

  middleName: "Middle Name",
  middleNamePlaceholder: "Enter Middle Name if any",

  lastName: "Last Name",
  lastNamePlaceholder: "Enter Last Name",
  lastNameBlankErrorMsg: "Please enter your last name.",

  defaultNameBottomText:
    "e.g. Name should be as per Passport or Driving License",
  defaultInputType: "text",
  nameRegex: "^[a-zA-Z\\s'-]+$",
  defaultNameErrorMsg:
    "Please only use letters (a-z), hyphens (-) and apostrophes ( ' ).",
  defaultFieldCanNotBeBlank: "Field cannot be blank.",

  dateOfBirth: "Date of Birth",
  dateOfBirthPlaceholder: "Enter Date of Birth",
  dateOfBirthBottomText: "e.g. DDMMYYYY",
  dateOfBirthErrorMsg:
    "Please enter correct date of birth as per below given example.",
  dateOfBirthRegex: "^\\d{4}-\\d{2}-\\d{2}$",

  emailAddress: "Email Address",
  emailAddressPlaceholder: "Enter Email Address & Verify",
  emailAddressBottomText: "e.g. email@example.com",
  emailAddressErrorMsg: "Please enter a valid email address.",
  emailAddressRegex: "^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+[\\.][a-zA-Z0-9-]{2,3}",
  emailAddressBlankErrorMsg: "Email address must be entered.",
  emailAddressButtonText: "Verify",

  mobileNumber: "UK Mobile Number",
  mobileNumberPlaceholder: "Enter Mobile Number & Verify",
  mobileNumberBottomText: "e.g. +44 0000000000",
  mobileNumberErrorMsg: "Please enter a valid mobile number.",
  mobileNumberRegex: "^0?(\\d{10}$)",
  mobileNumberButtonText: "Verify",

  postalAddress: "Postal Address Line1",
  postalAddressPlaceholder: "Search using your postcode",
  postalAddressBottomText: "Cant’t find your address?",
  postalAddressErrorMsg: "Please enter a valid mobile number.",
  postalAddressRegex: "[+]44[-]\\d{2}-\\d{4}\\s\\d{4}$",
  postalAddressButtonText: "Search",

  addressLine2: "Address Line 2",
  addressLine2Placeholder: "Address Line 2",

  addressLine3: "Address Line 3",
  addressLine3Placeholder: "Address Line 3",

  addressLine4: "Address Line 4",
  addressLine4Placeholder: "Address Line 4",

  townCity: "Town/City",
  townCityPlaceholder: "Town/City",
  townCityErrorMsg: "Please enter City name.",
  townCityBlankErrorMsg: "Please enter City name..",

  postalCode: "Postal Code",
  postalCodePlaceholder: "Postal Code",
  postalCodeErrorMsg:
    "Postal code length cannot be more than 7 alpha or numeric characters.",
  postalCodeBlankErrorMsg: "Please enter Postal Code.",
  postalCodeRegex: "^[a-zA-Z0-9]{1,7}$",

  stepperPersonalInformationTitle:
    "The personal information we have collected from you will be shared \
    with the fraud prevention agencies who will use it to prevent any \
    fraud and money-laundering and to verify your identity. If fraud is \
    detected, you could be refused certain services, finances or \
    employment. Further details of your information will be used by us and \
    these fraud prevention agencies and your data protection rights, can \
    be found reading our privacy notice.",
  employmentInfoNeedText: "Learn why we need this data",
  employmentStatusRadioTitle: "Employment Status",
  employmentStatusEmployed: "Employed",
  employmentStatusSelfEmployeed: "Self-Employed",
  employmentStatusRetired: "Retired",
  employmentStatusUnemployed: "Unemployed",
  employmentStatusStudent: "Student",
  employmentStatusHomemaker: "Homemaker",
  employmentIndustrySearchTitle: "What Industry do you work in",
  employmentInputJobRoleTitle: "Job Role",
  jobRolePlaceholder: "Enter Job Role",
  incomeRange1: "Below £25,000",
  incomeRange2: "£25,001 to £50,000",
  incomeRange3: "£50,001 to £100,000",
  incomeRange4: "£100,001 to £200,000",
  incomeRange5: "£200,001 or more",
  employmentIncomeRadioTitle: "Annual Income before Tax",
  unableToFindRelatedProduct:
    "Sorry unable to get related product. Please try after some time.",
  jobRoleErrorMessage: "Please enter Job Role.",
  industryInputErrorMessage: "Please select Industry you work in.",
  pleaseChooseEmployeeStatus: "Please choose your employment status",
  addItManually: "Add it manually",
  searchFromDataBase: "Search from data base",
  memorableSectionHeading: "Choose a memorable word",
  memorableSectionSubHeading:
    "We may ask you for this word if we need to verify you. This is an additional measure to keep your account safe.",
  memorableWordPlaceholder: "Enter  Memorable Word",
  memorableWordHelperText: "Minimum of 7 characters",
  memorableWordErrorMessage:
    "Please enter minimum 7 characters memorable word.",
  memorableSectionHintText: "Choose a hint for your memorable word.",
  memorableSectionHintPlaceholder: "Enter Hint",
  loginText: "Login",
  welcome: "Welcome",
  footerSavingsText: "Your savings are safe with us.",
  getTheAppText: "Get the App",
  followusText: "Follow Us",
  yes: "yes",
  us: "US",
  uk: "uk",
  another: "another",
  isUKCitizenOnly: "isUkCitizenOnly",
  otherThanUKCitizen: "otherThanUKCitizen",
  otherThanUKTaxNumber: "otherThanUKTaxNumber",
  taxNumber: "taxNumber",
  isTaxInputEnableForUS: "isTaxInputEnableForUS",
  isTaxInputEnableForAnother: "isTaxInputEnableForAnother",
  isTaxInputEnableForUK: "isTaxInputEnableForUK",
  pleaseSelectToContinue: "please select to continue.",
  pleaseSelectAnyValue: "Please select any value",
  mustSelectAtLeastOneOption: "You must select at least one option.",
  yourTaxNumberMustBeNineDigit: "Your tax number must be 9 digits.",

  stepOne: 0,
  stepTwo: 1,
  stepThree: 2,
  stepFour: 3,
  stepFive: 4,
  stepSix: 5,
  stepSeven: 6,
  stepEight: 7,

  one: 1,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  twenty: 20,
  cancelRequestHeading: "Cancel Request",
  cancelRequestDescription:
    "Are you sure you want to cancel the onboarding/ registration for opening an account? This action cannot be undone and all the drafted changes will be lost.",
  cancelRequestButtonText: "Submit",
  timerInfoSectionSubHeading:
    "There are just a few steps to complete before we can open your account, which include knowing a little bit more about you, your account preferences, and the Terms & Conditions.",
  timerInfoSectionInfoHeading:
    "Learn why we need to know a little more about you",
  timerInfoSectionRatePrefix: "Your rate:",
  reviewPagelandingText:
    "Please check and confirm all the information you have provided is correct. You can easily edit your details if required",
  reviewPageCheckBoxLabel: "I confirm, the above information is correct",
  reviewPageGrossText: "Gross",
  reviewPageMainHeading: "is nearly ready.",
  reviewModalHeading: "Why we need to know a little more about you",
  reviewModalDescription:
    "To help us meet our regulatory obligations, we need to ask you some additional income-related questions so that we can better understand how you are funding your account.\n\nWe respect your privacy and are committed to protecting your personal data. The way we store this data is in line with our privacy notice. ",
  productDetailsProductHeading: "Product",
  productDetailsInterestHeading: "Interest Rate",
  productDetailsTitle: "Product Details",
  aer: "AER",
  pleaseEnterOTP: "Please enter the code we sent to +44",
  chooseAProduct: "Choose a Product",
  verificationCodeInvalid:
    "The verification code is invalid. Please re-enter the code to try again.",
  verificationCodeValid: "The verification code is valid.",
  verificationCodeExpireTwentySec:
    "The verification code will expire in 20secs. Please enter the code to verify.",
  verificationCodeExpireResend:
    "The verification code has expired. Please re-send the code to try again.",
  changeMobileNumber: "Change Mobile Number",
  resendCode: "Resend Code",
  pleaseConfirmEmailAddress: "Please confirm your email address is correct,",
  dontWorryNotUsedInMarket:
    "Don’t worry - we won't use it for marketing without your permission.",
  reviewDetailsEmailAddress: "Email Address",
  reviewDetailsPhoneNumber: "Phone Number",
  reviewName: "Name",
  reviewTitle: "Title",
  reviewPersonalDetails: "Personal Details",
  dashboardHeading: "Account Summary",
  dashboardDiscoverMore: "Discover More",
  dashboardCurrentBalance: "Your current balance is",
  jobRoleBlankErrorMessage: "",
};

export default Strings;
