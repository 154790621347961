import React, { useCallback, useMemo, useReducer, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/redux-hooks";
import Strings from "../../../../theme/string";
import GenericViewFormComponent from "./GenericViewFormComponent";
import styles from "../../../../styleModules/reviewStepper.module.scss";
import DesignationField from "../../../personalInformation/personalInformationFields/designationField";
import {
  updateUserDetails,
  updateValidationIsRequired,
} from "../../../../redux/reducer/stepperSubProductSlice";
import DobField from "../../../personalInformation/personalInformationFields/dobField";
import NameField from "../../../personalInformation/personalInformationFields/nameField";
import {
  EditFlags,
  RootState,
} from "../../../../interfaces/reviewStepper.interface";
import { SaveButtonComponent } from "./miscComponents/saveButtonComponent";
import { EditButtonComponent } from "./miscComponents/editButtonComponent";
import {
  ActionTypeEnum,
  ReviewFieldEnum,
  ReviewNameFieldEnum,
} from "../../../../enums/reviewStepper.enums";

type ActionType =
  | { type: ActionTypeEnum.SET_EDIT_MODE; payload: keyof EditFlags }
  | { type: ActionTypeEnum.RESET_EDIT_MODE; payload: keyof EditFlags };

const initialEditFlags: EditFlags = {
  title: false,
  name: false,
  dateofbirth: false,
};

// FOR USE-REDUCER HOOK USED IN THIS COMPONENT
const editFlagsReducer = (state: EditFlags, action: ActionType): EditFlags => {
  switch (action.type) {
    case ActionTypeEnum.SET_EDIT_MODE:
      return { ...state, [action.payload]: true };
    case ActionTypeEnum.RESET_EDIT_MODE:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
};

const fieldComponents = {
  title: DesignationField,
  name: NameField,
  dateofbirth: DobField,
} as const;

type FieldComponentMapping = keyof typeof fieldComponents;

const PersonalDetailsReviewComponent = () => {
  const {
    emailId,
    mobileNumber,
    title,
    firstName,
    middleName,
    lastName,
    dateOfBirthValue,
  } = useAppSelector((state: RootState) => ({
    emailId: state.stepperDetails.stepperUserDetails.emailaddress.value,
    mobileNumber: state.stepperDetails.stepperUserDetails.mobilenumber.value,
    title: state.stepperDetails.stepperUserDetails.title.value,
    firstName: state.stepperDetails.stepperUserDetails?.firstname?.value,
    middleName: state.stepperDetails.stepperUserDetails?.middlename?.value,
    lastName: state.stepperDetails.stepperUserDetails?.lastname?.value,
    dateOfBirthValue:
      state.stepperDetails.stepperUserDetails?.dateofbirth?.value,
  }));
  const stepperDetails = useAppSelector(
    (state: RootState) => state.stepperDetails
  );
  const dataUserDetails = stepperDetails.stepperUserDetails;

  const [editFlags, dispatch] = useReducer(editFlagsReducer, initialEditFlags);
  const appDispatch = useAppDispatch();

  const editModeHandler = useCallback(
    (field: keyof EditFlags) => {
      dispatch({ type: ActionTypeEnum.SET_EDIT_MODE, payload: field });
    },
    [dispatch]
  );

  const saveModeHandler = useCallback(
    (field: keyof EditFlags, titles: string[]) => {
      if (titles.every((title) => dataUserDetails[title]?.isdraftValidate??true)) {
        titles.forEach((title) => {
          appDispatch(
            updateUserDetails({
              fieldName: title,
              value: dataUserDetails[title]?.draftValue??"",
              draftValue: dataUserDetails[title]?.draftValue??"",
              isValidate: dataUserDetails[title]?.isValidate??true,
              isdraftValidate: dataUserDetails[title]?.isdraftValidate??true,
            })
          );
        });
        dispatch({ type: ActionTypeEnum.RESET_EDIT_MODE, payload: field });
      } else {
        appDispatch(updateValidationIsRequired(true));
      }
    },
    [appDispatch, dataUserDetails]
  );

  const personalDetailsReadData = useMemo(
    () => [
      {
        title: `${Strings.reviewDetailsEmailAddress}`,
        fieldData: `${emailId}`,
      },
      {
        title: `${Strings.reviewDetailsPhoneNumber}`,
        fieldData: `${mobileNumber}`,
      },
    ],
    [emailId, mobileNumber]
  );

  const renderField = useCallback(
    (
      label: string,
      editFlag: boolean,
      value: string,
      field: keyof EditFlags,
      fieldType: FieldComponentMapping,
      titles: string[]
    ) => {
      const FieldComponent = fieldComponents[fieldType];
      return (
        <div key={field} className="col-6">
          <div className="d-flex align-items-center form-label gap-3">
            <div>
              <label htmlFor={`formField-${field}`}>
                {label}
                <span className="text-danger"> *</span>
              </label>
            </div>
            {editFlag && (
              <SaveButtonComponent
                onClick={() => saveModeHandler(field, titles)}
              />
            )}
          </div>
          {!editFlag && (
            <div className="d-flex">
              <div
                id={`formField-${field}`}
                className={styles.genericFormFieldStyle}
                aria-label={`${field} field`}
              >
                <div className={styles.genericFormFieldTextStyle}>{value}</div>
              </div>
              <EditButtonComponent onClick={() => editModeHandler(field)} />
            </div>
          )}

          {editFlag && (
            <div className="row gap-4 mt-4">
              {titles.map((name) => (
                <FieldComponent
                  key={name}
                  stepperDetails={stepperDetails}
                  fieldName={name as ReviewNameFieldEnum}
                  reviewFlag
                />
              ))}
            </div>
          )}
        </div>
      );
    },
    [editModeHandler, saveModeHandler, stepperDetails]
  );

  return (
    <>
      <div>
        <div className="d-flex align-items-center gap-3 mb-3">
          <div className={styles.informationTitleHeading}>
            {Strings.reviewPersonalDetails}
          </div>
        </div>
        <div className="row gap-4 mb-3">
          {renderField(
            Strings.reviewTitle,
            editFlags.title,
            title,
            ReviewFieldEnum.TITLE,
            ReviewFieldEnum.TITLE,
            [ReviewFieldEnum.TITLE]
          )}
          {renderField(
            Strings.reviewName,
            editFlags.name,
            `${firstName} ${middleName??""} ${lastName}`,
            ReviewFieldEnum.NAME,
            ReviewFieldEnum.NAME,
            [
              ReviewNameFieldEnum.FIRST_NAME,
              ReviewNameFieldEnum.MIDDLE_NAME,
              ReviewNameFieldEnum.LAST_NAME,
            ]
          )}
          {renderField(
            Strings.dateOfBirth,
            editFlags.dateofbirth,
            dateOfBirthValue.split("-").reverse().join("/"),
            ReviewFieldEnum.DATE_OF_BIRTH,
            ReviewFieldEnum.DATE_OF_BIRTH,
            [ReviewFieldEnum.DATE_OF_BIRTH]
          )}
        </div>
        <GenericViewFormComponent formData={personalDetailsReadData} />
      </div>
    </>
  );
};
export default PersonalDetailsReviewComponent;
