import { createSlice } from "@reduxjs/toolkit";
import { getStepperSubProductAction } from "./../actions/stepperSubproductAction";

const initialState = {
  stepperSubProduct: [],
  stepperUserDetails : {},
  stepperChoosedPlan : {},
  stepperCitizenAndTax : {
    isTaxInputEnableForUS : {value : false},
  },
  choosePlanConsent : false,
  reviewConfirmation:false,
  buttonValidatonIsRequired : false,
  stepperLinkUKBank : {},
  stepperEmployeeDetails : {}
};

export const stepperSubProductSlice = createSlice({
  name: "StepperSubProduct",
  initialState,
  reducers: {
    clearStates: () => initialState,

    updateUserDetails: (state,{payload}) => {
      const tmpObj = {[payload.fieldName] : payload} 
      state.stepperUserDetails = {...state.stepperUserDetails, ...tmpObj}
    },

    updateChoosePlan: (state, {payload}) =>{
      state.stepperChoosedPlan = payload;
    },

    updateChoosePlanConsent: (state, {payload}) =>{
      state.choosePlanConsent = payload;
    },

    updateReviewConfirmation: (state, {payload}) =>{
      state.reviewConfirmation = payload;
    },

    updateCitizenAndTax : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload.obj} 
      state.stepperCitizenAndTax = {...state.stepperCitizenAndTax, ...tmpObj};
    },

    updateValidationIsRequired : (state, {payload}) =>{
      state.buttonValidatonIsRequired = payload;
    },

    updatedLinkUKBank : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload} 
      state.stepperLinkUKBank = {...state.stepperLinkUKBank, ...tmpObj}
    },

    updateEmployeeDetails : (state, {payload}) =>{
      const tmpObj = {[payload.fieldName] : payload} 
      state.stepperEmployeeDetails = {...state.stepperEmployeeDetails, ...tmpObj}
    }
  }, 
  extraReducers: (builder) => {
    builder.addCase(getStepperSubProductAction.fulfilled, (state : any, { payload }) => {
      // TODO always keep in mind to take care wrong payload or empty payload        
      state.stepperSubProduct = payload
    });
  }
});

export const { clearStates, updateUserDetails, updatedLinkUKBank, updateChoosePlan, updateChoosePlanConsent, updateCitizenAndTax, updateValidationIsRequired, updateEmployeeDetails,updateReviewConfirmation } =
stepperSubProductSlice.actions;

export default stepperSubProductSlice.reducer;