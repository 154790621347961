import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Img } from "../../common/basic components/img";
import { INITIAL_COUNT } from "../../common/constant/customConstant";
import { updateChoosePlan } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";

export const SubProductSelectedPlan = (props: any) => {
  const dispatch = useAppDispatch();
  const { id: productName } = useParams();
  const { onClick, data, tmpProduct } = props;
  const stepperDetails : any = useAppSelector((state: any) => state.stepperDetails);
  const selectedProduct = stepperDetails?.stepperChoosedPlan;

  useEffect(()=>{
    if(selectedProduct?.productKey) 
      onClick(selectedProduct);
    else
      updateState({...tmpProduct});
  },[tmpProduct])

  const handlerOnClick = (item : {}) =>{
    onClick(item);
    updateState(item)
  }

  const updateState = (obj: any) => {
    dispatch<any>(updateChoosePlan(obj));
  };  

  const renderData = data?.map((item: any) => {
    const tmpItemName = item?.productFamily?.toLowerCase();
    const tmpId = productName?.split(' ')[0].toLowerCase();
    if (tmpItemName?.search(tmpId) >= 0) {
      return (
        <div className="col-md-3">
          <div className={`stepper-product ${tmpProduct?.productKey == (item.productKey) ? 'activeSelected' : 'notSelected'}`} key={item.productKey}>
            <div className="stepper-product-title">{item.productName}</div>
            <div className="stepper-product-value text-primary_one">{(item.interestRate && item.aer) ? `${item.interestRate}% Gross | ${item.aer}% AER` :
              item.interestRate && `${item.interestRate}% Gross` ||
              item.aer && `${item.aer}% AER`}
            </div>
            <div className="icon-div-choosePlan">
              <Img
                src={require(`../../assest/stepperProductDropIcon.png`)}
                className="account-type-image"
                alt={`${item?.name} icon`}
                onClick={() => handlerOnClick(item)}
              />
            </div>
          </div>
        </div>
      );
    }
  });
  return <div className="row stepper-product-label">{renderData}</div>;
};
