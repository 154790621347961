import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  savingAccountProduct: [
    {
      "id": 1,
      "title": "Fixed Term Deposit",
      "product": {
        "id": 1,
        "name": "Fixed Term Deposit",
        "description": [
            "Rates up to 5.07% AER (fixed)",
            "Fix for 6months, 1, 2 or 5 years.",
            "Access your interest or leave it with us to maximize growth."
        ],
        "src" :"../../assest/monu_FT_icon.png"
      }
    },
    {
      "id": 2,
      "title": "Notice Accounts",
      "product": {
        "id": 2,
        "name": "Notice Accounts",
        "description": [
            "Rates up to 5.15% AER (variable)",
            "Choice of notice for your short and medium term goals",
            "7 to 35 days advance notice for withdrawal."            
        ],
        "src" :"../../assest/monu_FT_icon.png"
      }
    },
    {
      "id": 3,
      "title": "Easy Access Savings",
      "product": {
        "id": 3,
        "name": "Easy Access Savings",
        "description": [
            "0.60% Gross / 0.60% AER (Variable) interest rate.",
            "24/7 Access to your money.",
            "Unlimited Withdrawal"
        ],
        "src" :"../../assest/monu_FT_icon.png"
      }
    }
  ]
};

export const savingAccountProductSlice = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    clearStates: () => initialState,
}
});

export const { clearStates } =
savingAccountProductSlice.actions;

export default savingAccountProductSlice.reducer;
