import React, { useCallback, useState, useEffect } from "react";
import ProductDetailsViewComponent from "./ReviewSubComponents/ProductDetailsViewComponent";
import { Checkbox } from "../../../common/basic components/checkBox";
import {
  ReviewData,
  RootState,
} from "../../../interfaces/reviewStepper.interface";
import TimerInfoSection from "./TimerInfoSection";
import Strings from "../../../theme/string";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { updateReviewConfirmation } from "../../../redux/reducer/stepperSubProductSlice";
import { getStepperReviewData } from "../../../services/appApi";
import PersonalDetailsReviewComponent from "./ReviewSubComponents/PersonalDetailsReviewComponent";

const StepperReviewComponent = () => {
  const { reviewConfirmation, buttonValidatonIsRequired } = useAppSelector(
    (state: RootState) => state.stepperDetails
  );
  const [reviewData, setReviewData] = useState<ReviewData>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    apiHandler();
  }, []);

  const apiHandler = useCallback(async () => {
    const apiData = await getStepperReviewData();
    if (apiData) {
      setReviewData(apiData);
    } else {
      console.error("Error fetching review data!");
    }
  }, []);


  return (
    <section aria-labelledby="review-stepper-section">
      <header id="review-stepper-header" className="mb-4">
        <div>{Strings.reviewPagelandingText}</div>
      </header>
      <article className="d-flex flex-column gap-4">
        {/* UNCOMMENT THE BELOW WHEN YOU WORK ON THEM */}
        <PersonalDetailsReviewComponent />
         {/*<NationalityAndTaxReviewComponent />
        <LinkedAccountReviewComponent /> */}
        <ProductDetailsViewComponent />
        <div className="row">
          <div className="col-md-6">
            <div className="terms-conditions-choosePlan">
              <Checkbox
                onChange={() => {
                  dispatch(updateReviewConfirmation(!reviewConfirmation));
                }}
                label={Strings.reviewPageCheckBoxLabel}
                isChecked={reviewConfirmation}
                inputClassName={"checkbox-width-zero"}
                labelClassName={reviewConfirmation ? "text-primary_one" : ""}
                aria-label="Confirmation checkbox"
              />
            </div>
            {buttonValidatonIsRequired && !reviewConfirmation && (
              <div className="text-danger">
                {Strings.pleaseSelectToContinue}
              </div>
            )}
          </div>
        </div>
        <TimerInfoSection timerInfoSection={reviewData?.timerInfoSection} />
      </article>
    </section>
  );
};
export default StepperReviewComponent;
