import React from "react";
import {
  hasSufficientBalance,
  getAccountFundingDeadline,
  isFundingPeriodActive,
} from "../../common/utils/dashboardButtonsConditions";
import styles from "../../styleModules/dashboard.module.scss";

interface Props {
  account: {
    accountName: string;
    accountNumber: string;
    accountOwner: string;
    accountStatus: string;
    accountType: string;
    aer: number;
    balance: number;
    interestRate: number;
    creationDate: string;
  };
}

const dashboardButtons: React.FC<Props> = ({ account }) => {
  const { accountType, balance, creationDate } = account;
  const funded = hasSufficientBalance(balance);
  const withinFundingPeriod = isFundingPeriodActive(creationDate);
  const fundingEndDate = getAccountFundingDeadline(creationDate);

  if (accountType === "FT") {
    return (
      <div>
        {withinFundingPeriod && !funded && (
          <button>
            Make your first deposit by
            {new Date(fundingEndDate).toLocaleDateString()}
          </button>
        )}
      </div>
    );
  } else if (accountType === "NT" || accountType === "EA") {
    return (
      <div className="pb-2 fs-6">
        {funded ? (
          <div className="d-flex justify-content-start gap-2 align-items-start flex-wrap">
            <button className={`btn btn-primary_one py-1 px-2`}>Add Money</button>
            <button className={`btn btn-primary_two py-1 px-2`}>Withdraw</button>
            <button className={`btn btn-primary_three py-1 px-2`}>Move Money</button>
          </div>
        ) : (
          <div className="d-flex justify-content-start gap-2 align-items-start flex-wrap">
            <button className={`btn btn-primary_one text-nowrap py-1 px-2`}>
              Make your first deposit
            </button>
            <button className={`btn btn-primary_three py-1 px-2`}>
              Move Money
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return <>No Data</>;
  }
};

export default dashboardButtons;
