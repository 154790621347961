import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import { dashboardDiscoverMoreAction } from "../../redux/actions/dashboardDiscoverMoreAction";
import { Img } from "../../common/basic components/img";

const DiscoverMore = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch<any>(dashboardDiscoverMoreAction());
  }, []);

  const discoverMoreData: any = useAppSelector(
    (state: any) => state.dashboardDiscoverMore?.discoverMoreObj
  );

  const data = discoverMoreData?.products;

  const RightArrow = () => {
    return (
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <div className="" role="button">
            <span>
              <img
                alt={"Right Arrow Icon"}
                src={require(`../../assest/OpenLinkRightChevron.png`)}
                className="open-link-right-rotated-icon"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const productsElement = data?.map((product: any) => {
    return (
      <div
        key={product.name}
        className={`border rounded col-12 col-md-6 col-lg-3 m-2 py-2`}
        style={{ minWidth: "416px", minHeight: "144px" }}
      >
        <div className="d-flex justify-content-between">
          <div className="p-3">
            <div className="d-flex justify-content-start align-items-center">
              <span className="fw-bold fs-24">{product.name}</span>
              <Link to="#" className="mx-4">
                <RightArrow />
              </Link>
            </div>
            <p>{product.description}</p>
            {product.links?.map((link: string) => (
              <Link key={link} to="">
                {link}
              </Link>
            ))}
          </div>
          <div className="head-image">
            <Img src={product?.src} loading="lazy" alt="" />
          </div>
        </div>
      </div>
    );
  });

  return <>{productsElement}</>;
};

export default DiscoverMore;
