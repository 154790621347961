import axios from "axios";
import { BASE_URL } from "./url";

export const getAccountProductPublic = async (url: any) => {
  let tmpData;
  await axios
    .get(`${BASE_URL}saving-products?loyaltyProduct=false&active=true&channel=Web`
    )
    .then((response) => {
      tmpData = [...response.data.data.savingProduct];
    })
    .catch((error) => {
      console.error(error);
    });
  return tmpData;
};

export const getAccountDetails = async (url :any, token: any) => {
  let tmpData;
  await axios
    .get(`${url}`,
      {headers: {
        'Authorization': `${token}`,
        // 'Access-Control-Allow-Credentials':true,
        'Content-Type': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        // 'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
      }}
    )
    .then((response) => {
      tmpData = response;
    })
    .catch((error) => {
      console.error(error);
    });
  return tmpData;
};


