import { FunctionComponent, useEffect, useState } from "react";
import { getLinkBankAccountData } from "../../services/appApi";
import { Input } from "../../common/basic components/input";
import { RadioButton } from "../../common/basic components/radioButton";
import Strings from "../../theme/string";
import { numberFormat, numberFormatWithDecimal, sortCodeAutoFormat } from "../../common/modules/validation";
import { INITIAL_COUNT } from "../../common/constant/customConstant";
import { useParams } from "react-router-dom";
import { updatedLinkUKBank } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";

interface LinkBankAccountData {
    data: {
        description: string;
    }
}

const StepperLinkBankAccount = (props: any) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const INTEREST_RADIO_CONST = [
        `${Strings.interestPaidOption1} ${id}`,
        Strings.interestRadioLinkedAccount
    ];
    const stepperDetails: any = useAppSelector((state: any) => state.stepperDetails);
    const mainObj = stepperDetails?.stepperLinkUKBank;
    const [linkBankData, setLinkBankData] = useState<LinkBankAccountData>();

    useEffect(() => {
        if(!mainObj?.interestBeingPaid?.value)dispatching("interestBeingPaid", "", false );
        apiHandler();
    }, []);

    const dispatching = (key : string, tmpVal : string, isValid : boolean) =>{
        dispatch<any>(
            updatedLinkUKBank({
              fieldName: key,
              value: tmpVal,
              isValidate: isValid,
            })
          );
    }

    const apiHandler = async () => {
        let apiData = getLinkBankAccountData();
        if (apiData) {
            setLinkBankData(apiData);
        } else {
            console.error("Error fetching link bank account data!");
        }
    };

    const handleSortCodeInput = (valueParam: string, key : string) => {
        let targetSortCodeValue: any = sortCodeAutoFormat(valueParam);
        let tempFormat = valueParam.replace(/-/g, "");
        dispatching(key, targetSortCodeValue, tempFormat.length == Strings.six ? true : false);
    }
    
    const handleAccountNumberInput = (valueParam: string, key : string) => {
        let targetAccountValue: any = numberFormat(valueParam);
        dispatching(key, targetAccountValue, targetAccountValue.length == Strings.eight ? true : false);
    }

    const handleDepositAmountInput = (valueParam: any, key: string) => {
        let targetDepositAmountValue: any = numberFormatWithDecimal(valueParam);
        dispatching(key, targetDepositAmountValue, targetDepositAmountValue.length > INITIAL_COUNT ? true : false);
    }

    return (
        <>
            <div className="linkBankSection">
                <div className="row">
                    <p className="new-line lh-base">{linkBankData?.data?.description}</p>
                </div>
                <InputRenderer
                    label={Strings.linkBankSortCodeLabel}
                    placeholder={Strings.linkBankSortCodePlaceholder}
                    value={mainObj?.sortCode?.value}
                    updateInput={(e:React.ChangeEvent<HTMLInputElement>) => handleSortCodeInput(e?.target?.value, 'sortCode')}
                    helperText={Strings.linkBankSortCodeHelperText}
                    maxLength={Strings.eight}
                    isError={stepperDetails.buttonValidatonIsRequired && mainObj?.sortCode?.value?.replace(/-/g, "").length != Strings.six ? true : false}
                    errorMessage={mainObj?.sortCode?.value?.replace(/-/g, "").length == INITIAL_COUNT ? Strings.linkBankSortCodeBlankError : Strings.linkBankSortCodeError}
                />
                <InputRenderer
                    label={Strings.linkBankAccountNumberLabel}
                    placeholder={Strings.linkBankAccountNumberPlaceholder}
                    value={mainObj?.accountNumber?.value}
                    updateInput={(e:React.ChangeEvent<HTMLInputElement>) => handleAccountNumberInput(e?.target?.value, "accountNumber")}
                    helperText={Strings.linkBankAccountNumberHelperText}
                    maxLength={Strings.eight}
                    isError={stepperDetails.buttonValidatonIsRequired && mainObj?.accountNumber?.value?.length != Strings.eight ? true : false}
                    errorMessage={mainObj?.accountNumber?.value?.length == INITIAL_COUNT ? Strings.linkBankAccountNumberBlankError : Strings.linkBankAccountNumberError}
                />
                <div className="row mb-3">
                    <div className="col-md-8 col-lg-6">
                        <label className="form-label">
                            {Strings.linkBankInterestRadioLabel}
                            <span className="text-danger"> * </span>
                        </label>
                        {INTEREST_RADIO_CONST.map((item: any, key: number) => {
                            return (
                                <div
                                    key={item}
                                    className={`terms-conditions-choosePlan ${key == INITIAL_COUNT && 'mb-3'}`}>
                                    <RadioButton
                                        onChange={() => dispatching("interestBeingPaid", item, true )}
                                        label={item}
                                        inputClassName={"checkbox-width-zero"}
                                        name="SelectInterestPaidAccountType"
                                        isChecked={mainObj?.interestBeingPaid?.value.toLowerCase() == item.toLowerCase()}
                                    />
                                </div>
                            );
                        })}
                        {stepperDetails.buttonValidatonIsRequired && !mainObj?.interestBeingPaid?.value && <div className="fst-italic font-size-12px text-danger">
                            {Strings.pleaseSelectAnyValue}
                        </div>}
                    </div>
                </div>
                <InputRenderer
                    label={Strings.linkBankAmountDepositLabel}
                    placeholder={Strings.linkBankAmountDepositPlaceholder}
                    value={mainObj?.depositAmount?.value}
                    updateInput={(e:React.ChangeEvent<HTMLInputElement>) => handleDepositAmountInput(e?.target?.value, "depositAmount")}
                    helperText={`${Strings.example} ${mainObj?.depositAmount?.value} ${Strings.linkBankAmountDepositHelperText}`}
                    isError={stepperDetails.buttonValidatonIsRequired && !mainObj?.depositAmount?.isValidate ? true : false}
                    errorMessage={Strings.linkBankAmountDepositError}
                />
            </div>
            <span></span>
        </>
    );
};

export default StepperLinkBankAccount;

interface InputRendererProps {
    label?: string;
    placeholder?: string;
    value?: string;
    updateInput?: object;
    helperText?: string;
    maxLength?: number;
    errorMessage?: string;
    validateInput?: object;
    isError?: boolean;
}

const InputRenderer: FunctionComponent<InputRendererProps> = ({ label, placeholder, value, updateInput, helperText, maxLength, errorMessage, validateInput, isError }) => {
    return (<div className="row mb-3">
        <div className="col-md-8 col-lg-6">
            <label className="form-label">
                {label}
                <span className="text-danger"> * </span>
            </label>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={updateInput}
                className="form-control"
                maxLength={maxLength}
                onBlur={validateInput}
            />
            {isError && <div className="fst-italic font-size-12px text-danger">
                {errorMessage}
            </div>}
            <span className="fst-italic font-size-12px">
                {helperText}
            </span>

        </div>
    </div>)
}
