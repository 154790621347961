import { useEffect, useState } from "react";
import usInformationData from "../../assest/json/usInformationPopup.json";
import { Checkbox } from "../../common/basic components/checkBox";
import { RadioButton } from "../../common/basic components/radioButton";
import { InformationDisplayPopup } from "../../common/components/InfomationDisplayPopup";
import SearchCheckBox from "../../common/components/searchCheckBox";
import { TaxDisplayComponent } from "../../common/components/stepper/taxDisplayComponent";
import { ARRAY_INITIAL_COUNT, INITIAL_COUNT, NINE_COUNTER } from "../../common/constant/customConstant";
import { getCountryListAction } from "../../redux/actions/formSelectListsAction";
import { updateCitizenAndTax } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import StringData from "./stringCitizenShipNdTaxInformation";

export const StepperCitizenShipNdTaxInfromation = (props: any) => {
  const dispatch = useAppDispatch();
  const {countryList, stepperDetails} = useAppSelector((state: any) => state);
  const mainObj = stepperDetails.stepperCitizenAndTax;
  const tmpCountry = countryList?.countryList?.data?.records?.countriesAndStates?.countries;
  const Country = tmpCountry?.map((item: any) => {
    return { ...item, value: item.isoCode };
  });
  const [isPopupEnable, setIsPoupEnabled] = useState(false);

  const setIsUkCitizenOnlyHandler = (item : string) =>{
    dispatch<any>(updateCitizenAndTax({obj : {value : item}, fieldName : Strings.isUKCitizenOnly}))
  }
  
  const setOtherThanUKCitizenHandler = (item:any, tmpFieldName : string) =>{
    dispatch<any>(updateCitizenAndTax({obj : {isValidate : item.length > INITIAL_COUNT ? true : false, value : item}, fieldName : tmpFieldName}))
  }

  const isCheckBoxChecked = () =>{
    if(!stepperDetails?.buttonValidatonIsRequired)
      return false;
    if(stepperDetails?.buttonValidatonIsRequired && (mainObj?.isTaxInputEnableForAnother?.value || mainObj?.isTaxInputEnableForUK?.value || mainObj?.isTaxInputEnableForUS?.value))
      {return false;}
    else
      {return true;}
  }

  const updateOtherCountryTaxField = (val: any, countryName: string) => {
    let tmpObj: any[] = [];
    if(mainObj?.taxNumber?.length > INITIAL_COUNT)
    {
      tmpObj = [...mainObj.taxNumber];
      const tmpIndex = mainObj.taxNumber.findIndex((item:any)=>item?.countryName == countryName)
      if(tmpIndex != ARRAY_INITIAL_COUNT)
      {
        tmpObj.splice(tmpIndex,1);
      }
    }
    else
    {
      tmpObj = mainObj?.taxNumber && mainObj?.taxNumber.length > INITIAL_COUNT ? [...mainObj.taxNumber] : [];
    }
    tmpObj.push({countryName, value : val, isValidate : val.length == NINE_COUNTER ? true : false});
    dispatch<any>(updateCitizenAndTax({obj : tmpObj
      , fieldName : Strings.taxNumber}))
  };

  const updateTaxResidencyHandler = (key: any) => {
    if (key == Strings.us.toLowerCase()) {
      dispatch<any>(updateCitizenAndTax({obj : {value : !mainObj?.isTaxInputEnableForUS?.value}, fieldName : Strings.isTaxInputEnableForUS}))
    };
    if (key === Strings.another){
      dispatch<any>(updateCitizenAndTax({obj : {value : !mainObj?.isTaxInputEnableForAnother?.value}, fieldName : Strings.isTaxInputEnableForAnother}))
    }
    if (key === Strings.uk){
      dispatch<any>(updateCitizenAndTax({obj : {value : !mainObj?.isTaxInputEnableForUK?.value}, fieldName : Strings.isTaxInputEnableForUK}))
    }
  };

  const getTaxNumberForCountry = (val : string) =>{
    const obj = mainObj?.taxNumber?.find((item : any)=>{      
      return item.countryName == val})
      return obj?.value;
  }

  const isTaxNumberField = (tmpVal : string) =>{
    let isValidate = mainObj?.taxNumber?.find((item : any)=>{      
      return item.countryName == tmpVal && item.isValidate})
    if(isValidate) return false;
    else return true;
  } 

  const isTaxNumberFieldHasValue = (tmpVal : string)=>{
    let isValidate = mainObj?.taxNumber?.findIndex((item : any)=>{      
      return item.countryName == tmpVal && item.value})
    return isValidate > ARRAY_INITIAL_COUNT ? true : false;
  }

  useEffect(() => {
    dispatch<any>(getCountryListAction());
  }, []);

  return (
    <>
      {isPopupEnable && (
        <InformationDisplayPopup
          show={isPopupEnable}
          onClick={() => setIsPoupEnabled(false)}
          data={usInformationData}
        />
      )}
      <div className="linkBankSection">
        <div className="row">
          <p className="new-line lh-base"> 
            {StringData.selectCategoryTitle}
            </p>
          </div>
        <div className="row mb-3">
        <div className=" col-md-8 col-lg-6">
        <label className="form-label">
          {StringData?.selectIsCountryUK?.title} <span className="text-danger"> * </span>
        </label>
        {StringData?.selectIsCountryUK?.radioButton.map((item: any) => {
          return (
            <>
              <div className="terms-conditions-choosePlan consent-choosePlan">
                <RadioButton
                  onChange={() => setIsUkCitizenOnlyHandler(item?.toLowerCase())}
                  label={item}
                  inputClassName={"checkbox-width-zero"}
                  name="selectIsCountryUK"
                  isChecked={mainObj?.isUkCitizenOnly?.value && mainObj?.isUkCitizenOnly?.value == item.toLowerCase()}
                />
              </div>
            </>
          );
        })}
        {stepperDetails?.buttonValidatonIsRequired && !mainObj?.isUkCitizenOnly?.value && (
        <div className="text-danger">{Strings.pleaseSelectAnyValue}</div>
        )}
        </div>
        </div>
        {mainObj?.isUkCitizenOnly?.value == Strings.yes && (
          <div className="row mb-3">
            <div className="col-md-8 col-lg-6">
              <label className="form-label">
                {StringData.selectOtherCountry} <span className="text-danger"> * </span>
              </label>
              <SearchCheckBox
                options={Country}
                setSelectedOptions={(item:any)=>setOtherThanUKCitizenHandler(item, Strings.otherThanUKCitizen)}
                selectedOptions={mainObj?.otherThanUKCitizen?.value}
              />
            </div>
          </div>
        )}
        {stepperDetails?.buttonValidatonIsRequired && mainObj?.isUkCitizenOnly?.value == Strings.yes && !mainObj?.otherThanUKCitizen?.isValidate && (
        <div className="text-danger">{Strings.defaultFieldCanNotBeBlank}</div>
        )}

        <div className="row mb-3">
        <div className=" col-md-8 col-lg-6">
        <label className="form-label">
          {StringData.selectTaxRecidency.title} <span className="text-danger"> * </span>
        </label>
        {StringData?.selectTaxRecidency?.checkInputs.map((item: any) => (
          <div
            className="terms-conditions-choosePlan search-input"
            key={item.country}
          >
            <Checkbox
              label={item.label}
              informationTrue={
                item && item?.country?.toLowerCase().search(Strings.us) >= INITIAL_COUNT
              }
              inputClassName={"checkbox-width-zero"}
              labelClassName={"text-color-green"}
              isChecked={mainObj?.[item.checked]?.value}
              onChange={() => updateTaxResidencyHandler(item.country)}
              onClickPopupHandler={() => setIsPoupEnabled(true)}
            />
          </div>
        ))}
        {isCheckBoxChecked() && (
        <div className="text-danger">{Strings.mustSelectAtLeastOneOption}</div>
        )}
        </div>
        </div>

        {mainObj?.isTaxInputEnableForAnother?.value && (
          <div className="row mb-3">
            <div className="col-md-8 col-lg-6">
              <label className="form-label">{StringData.selectTaxCountry} <span className="text-danger"> * </span></label>
              <SearchCheckBox
                options={Country}
                setSelectedOptions={(item:any)=>setOtherThanUKCitizenHandler(item, Strings.otherThanUKTaxNumber)}
                selectedOptions={mainObj?.otherThanUKTaxNumber?.value}
              />
            </div>
          </div>
        )}
         {stepperDetails?.buttonValidatonIsRequired && mainObj?.isTaxInputEnableForAnother?.value && !mainObj?.otherThanUKTaxNumber?.isValidate && (
        <div className="text-danger">{Strings.defaultFieldCanNotBeBlank}</div>
        )}
        
        {mainObj?.isTaxInputEnableForAnother?.value &&
          mainObj?.otherThanUKTaxNumber?.isValidate &&
          mainObj?.otherThanUKTaxNumber?.value.map((item: any) => {
            return (
              <>
              <TaxDisplayComponent
                key={item?.label}
                label={`${Strings.taxNumberFor} ${item.label}`}
                placeholder={Strings.enterTextNumber}
                bottomText={Strings.taxFormatExample}
                updateCountryHandler={(e:React.ChangeEvent<HTMLInputElement>) =>
                  updateOtherCountryTaxField(e, item?.label)
                }
                value={getTaxNumberForCountry(item?.label)}
              />
              {stepperDetails?.buttonValidatonIsRequired && isTaxNumberField(item?.label) && (
                <div className="text-danger">{isTaxNumberFieldHasValue(item?.label)? Strings.yourTaxNumberMustBeNineDigit : Strings.defaultFieldCanNotBeBlank}</div>
                )}
                </>
            );
          })} 

        {mainObj?.isTaxInputEnableForUS?.value && (
          <TaxDisplayComponent
            label={`${Strings.taxNumberFor} US`}
            placeholder={Strings.enterTextNumber}
            bottomText={Strings.taxFormatExample}
            updateCountryHandler={(e:React.ChangeEvent<HTMLInputElement>) =>
              updateOtherCountryTaxField(e, Strings.us)
            }
            value={getTaxNumberForCountry(Strings.us)}
          />
        )}
        {stepperDetails?.buttonValidatonIsRequired && mainObj?.isTaxInputEnableForUS?.value && isTaxNumberField(Strings.us) && (
        <div className="text-danger">{isTaxNumberFieldHasValue(Strings.us)? Strings.yourTaxNumberMustBeNineDigit : Strings.defaultFieldCanNotBeBlank}</div>
        )}
      </div>
    </>
  );
};