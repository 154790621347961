import React, { useState } from "react";
import { Button } from "../../common/basic components/button";
import { Img } from "../../common/basic components/img";
import { updateUserDetails } from "../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch } from "../../redux/redux-hooks";
import confirmationDone from "../../assest/validateCompletedTick.svg";
import { PART_OF_ADDRESS } from "../../common/constant/customConstant";

export const ValidationInput = (props: any) => {
  const dispatch = useAppDispatch();
  const [tmpValue, setTmpValue] = useState("");

  const {
    value,
    isError,
    onFocus,
    onBlur,
    placeHolder,
    type,
    id,
    lableValue,
    bottomText,
    spanclassName,
    labelDangerRequired,
    isBottomText,
    regExp,
    errorMsg,
    isButton,
    buttonText,
    blankErrorMsg,
    maxLength,
    isPrefixRequired,
    prefixeValue,
    disabled,
    reviewFlag,
    mainValue,
    mainValueValidation,
    buttonValidate,
    isConfirm,
    confirmationClick
  } = props;

  // const onClickValidation = (key: string) => {
  //   setEnableValidationPopup(!enableValidationPopup);
  // };

  const validateInputAndUpdate = (e: any, key: any) => {
    const tmpVal = e.target.value;
    const tmpReg = new RegExp(regExp);
    const isValidate = tmpReg.test(tmpVal);

    if (isValidate || PART_OF_ADDRESS.includes(key)) {
      dispatch<any>(
        updateUserDetails({
          fieldName: key,
          value: !reviewFlag ? tmpVal : mainValue,
          isValidate: true,
          draftValue: tmpVal,
          isdraftValidate: true,
        })
      );
    } else {
      dispatch<any>(
        updateUserDetails({
          fieldName: key,
          value: !reviewFlag ? tmpVal : mainValue,
          isValidate: !reviewFlag ? false : mainValueValidation,
          draftValue: tmpVal,
          isdraftValidate: false,
        })
      );
    }
    setTmpValue(tmpVal);
  };

  return (
    <div className={`${!reviewFlag && "col-md-8 col-lg-6"}`}>
      <label htmlFor={id} className="form-label">
        {lableValue}
        {labelDangerRequired && <span className="text-danger"> * </span>}
      </label>
      <div className="input-group">
        {isPrefixRequired && (
          <span className="input-group-text">{prefixeValue}</span>
        )}
        <input
          type={type}
          className="form-control"
          id={id}
          placeholder={placeHolder}
          value={value}
          onChange={(e) => validateInputAndUpdate(e, id)}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength}
          disabled={disabled}
        />
        {isConfirm && <div className="otp-tick-mark"><Img src={confirmationDone} /></div>}
        {isButton && (
          <Button
            className="btn btn-primary_one border-radius-0"
            disabled={buttonValidate}
            label={buttonText}
            onClick={confirmationClick}
          />
        )}
      </div>
      {isError && (
        <div className="text-danger">{tmpValue ? errorMsg : blankErrorMsg}</div>
      )}
      {isBottomText && <div className={spanclassName}>{bottomText}</div>}
    </div>
  );
};
