import { FunctionComponent, useEffect, useState } from "react";
import { getEmploymentData } from "../../services/appApi";
import { Input } from "../../common/basic components/input";
import { RadioButton } from "../../common/basic components/radioButton";
import Strings from "../../theme/string";
import { getIndustriesListAction } from "../../redux/actions/formSelectListsAction";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Select from 'react-select';
import { EmploymentInfoModal } from "./employmentInfoModal";
import { updateEmployeeDetails } from "../../redux/reducer/stepperSubProductSlice";
import { findValueInArray } from "../../common/modules/findValueInArray";
import { EMPLOYMENT_STATUS_RADIO_CONST, INCOME_RADIO_CONST, VERIFY_EMPLOYEE_OTHER_JOB } from "../../common/constant/customConstant";
import { JOB_ROLE_VALIDATION } from "../../common/constant/regexConstants";

interface EmploymentContent {
    data: {
        description: string;
    },
    modalData: {
        title: string;
        description: string;
    }
}

const StepperEmploymentDetails = (props: any) => {
    const dispatch = useAppDispatch();
    const { industriesList : IndustriesReduxState, stepperDetails } = useAppSelector((state: any) => state);
    const tempIndustries = IndustriesReduxState?.industriesList?.data?.industries;
    const mainObj = stepperDetails?.stepperEmployeeDetails;
    const INDUSTRIES_LIST = tempIndustries?.map((item: any) => {
        return { ...item, value: item.key, label: item.value };
    });

    const [employmentContent, setEmploymentContent] = useState<EmploymentContent>();
    const [isPopUpEnabled, setIsPopUpEnabled] = useState(false);

    useEffect(() => {
        dispatch<any>(getIndustriesListAction());
        apiHandler();
    }, []);

    const dispatching = (key : string, tmpVal : string, isValid : boolean) =>{
        dispatch<any>(
            updateEmployeeDetails({
              fieldName: key,
              value: tmpVal,
              isValidate: isValid,
            })
          );
    }

    function checkOtherJobIsSelected() {
        //Verify if employment is not student, unemployeed or homemaker
        if(findValueInArray(VERIFY_EMPLOYEE_OTHER_JOB, mainObj?.employeeStatus?.value))return true;
        return false;
    }

    const apiHandler = async () => {
        let apiData = getEmploymentData();
        if (apiData) {
            setEmploymentContent(apiData);
        } else {
            console.error("Error fetching employment details data!");
        }
    };

    const updateJobRoleHandler = (tmpRol : string) =>{
        const isValidate = JOB_ROLE_VALIDATION.test(tmpRol);
        dispatching("jobRole", tmpRol, isValidate )
    }



    return (
        <div className="employementDetailsSection mt-4">
            <div className="row">
                <p className="new-line lh-base">{employmentContent?.data?.description}</p>
            </div>
            <div 
                className="d-flex justify-content-start mb-3 customEmploymentInfoText"
                role="link" onClick={() => setIsPopUpEnabled(true)}
            >
                <div>
                    <img
                        alt={"information icon"}
                        src={require(`../../assest/info-circle-fill.png`)}
                    />
                </div>
                <span className="infoTextSummaryWithIcon">
                    {Strings.employmentInfoNeedText}
                </span>
            </div>
            <div className="row">
                <div className="col-md-8 col-lg-6">
                    <label className="form-label">
                        {Strings.employmentStatusRadioTitle}
                        <span className="text-danger"> * </span>
                    </label>
                    {EMPLOYMENT_STATUS_RADIO_CONST.map((item: any, key: number) => {
                        return (
                            <div
                                key={item}
                                className={`terms-conditions-choosePlan mb-3`}>
                                <RadioButton
                                    onChange={() => dispatching("employeeStatus", item, true )}
                                    label={item}
                                    inputClassName={"checkbox-width-zero"}
                                    name="SelectEmploymentStatus"
                                    isChecked={mainObj?.employeeStatus?.value && mainObj?.employeeStatus?.value.toLowerCase() == item.toLowerCase()}
                                />
                            </div>
                        );
                    })}
                    {stepperDetails?.buttonValidatonIsRequired && !mainObj?.employeeStatus?.isValidate && <div className="fst-italic font-size-12px text-danger fw-bold">
                        {Strings.pleaseChooseEmployeeStatus}
                    </div>}
                </div>
            </div>
            {checkOtherJobIsSelected() && <>
                <div className="row mb-3">
                    <div className="col-md-8 col-lg-6">
                        <div className="stepper-product-label form-label">
                            {Strings.employmentIndustrySearchTitle} <span className="text-danger"> * </span>
                        </div>
                        <Select
                            options={INDUSTRIES_LIST}
                            defaultValue={mainObj?.jobIndustry?.value}
                            onChange={(e: any) => {
                                let tempIndustry: string = e?.label;
                                dispatching("jobIndustry", tempIndustry, true )
                            }}
                            placeholder={Strings.typeToSearch}
                        />
                    </div>
                    {stepperDetails?.buttonValidatonIsRequired && !mainObj?.jobIndustry?.isValidate && <div className="fst-italic font-size-12px text-danger fw-bold">
                        {Strings.industryInputErrorMessage}
                    </div>}
                </div>
                <InputRenderer
                    label={Strings.employmentInputJobRoleTitle}
                    placeholder={Strings.jobRolePlaceholder}
                    value={mainObj?.jobRole?.value}
                    updateInput={(e: any) => updateJobRoleHandler(e?.target?.value)}
                    isError={stepperDetails?.buttonValidatonIsRequired && !mainObj?.jobRole?.isValidate}
                    errorMessage={mainObj?.jobRole?.value ? Strings.jobRoleErrorMessage : Strings.jobRoleBlankErrorMessage}
                />
                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        <label className="form-label">
                            {Strings.employmentIncomeRadioTitle}
                            <span className="text-danger"> * </span>
                        </label>
                        {INCOME_RADIO_CONST.map((item: any, key: number) => {
                            return (
                                <div
                                    key={item}
                                    className={`terms-conditions-choosePlan mb-3`}>
                                    <RadioButton
                                        onChange={() => dispatching("incomeRange", item?.toLowerCase(), true )}
                                        label={item}
                                        inputClassName={"checkbox-width-zero"}
                                        name="SelectIncomeRange"
                                        isChecked={mainObj?.incomeRange?.value && mainObj?.incomeRange?.value.toLowerCase() == item.toLowerCase()}
                                    />
                                </div>
                            );
                        })}
                        {stepperDetails?.buttonValidatonIsRequired && !mainObj?.incomeRange?.isValidate && <div className="fst-italic font-size-12px text-danger fw-bold">
                        {Strings.pleaseSelectAnyValue}
                    </div>}
                    </div>
                </div>
            </>}
            {isPopUpEnabled && (
                <EmploymentInfoModal
                    show={true}
                    onClick={() => setIsPopUpEnabled(false)}
                    data={employmentContent?.modalData}
                />
            )}
        </div>
    );
};

export default StepperEmploymentDetails;

interface InputRendererProps {
    label?: string;
    placeholder?: string;
    value?: string;
    updateInput?: object;
    helperText?: string;
    maxLength?: number;
    errorMessage?: string
    isError:boolean;
    validateInput?: object;
}

const InputRenderer: FunctionComponent<InputRendererProps> = ({ label, placeholder, value, updateInput, helperText, maxLength, errorMessage, validateInput, isError}) => {
    return (<div className="row mb-3">
        <div className="col-md-8 col-lg-6">
            <label className="form-label">
                {label}
                <span className="text-danger"> * </span>
            </label>
            <Input
                placeholder={placeholder}
                value={value}
                onChange={updateInput}
                className="terms-conditions-choosePlan stepper-product-row form-control"
                maxLength={maxLength}
                onBlur={validateInput}
            />
        {isError && <div className="fst-italic font-size-12px text-danger fw-bold">
                {errorMessage}
            </div>}
            <span className="fst-italic font-size-12px">
                {helperText}
            </span>

        </div>
    </div>)
}
