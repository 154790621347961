import React from "react";
import Modal from "react-bootstrap/Modal";

export const HowWeUseDataModal = (props: any) => {
    const { data, onClick, show } = props;
    return (
        <Modal
            show={show}
            size={"xl"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="customHowWeUseDataModal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{data?.modalHeading}</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClick}
                ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="new-line">{data?.modalDescription}</div>
            </Modal.Body>
            {data?.modalLinkText?   <Modal.Footer>
                <div className="useDataModalFooter"  onClick={() => window.open(data?.modalLinkUrl)}>
                    <span>{data?.modalLinkText}</span>
                    <span className="me-2">
                        <img
                            src={require(`../../assest/OpenLinkRightChevron.png`)}
                            className="open-link-right-rotated-icon"
                        />
                    </span>
                </div>
            </Modal.Footer>:<div className="mb-4"></div>}
        </Modal>
    );
};