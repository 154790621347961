import React, { useEffect } from "react";
import { fetchDashboardData } from "../../services/fetchDashboardData";
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks";
import Strings from "../../theme/string";
import DashboardButtons from "./DashboardButtons";
import styles from "../../styleModules/dashboard.module.scss";
import { Link } from "react-router-dom";
import DiscoverMore from "./DiscoverMore";

const DashboardComponent = () => {
  const {
    data: accounts,
    isLoading,
    error,
  } = useAppSelector((state: any) => state.dashboardAccountData);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch<any>(fetchDashboardData());
  }, [dispatch]);

  const RightArrow = () => {
    return (
      <div className="row">
        <div className="col-md-8 col-lg-6">
          <div className="" role="button">
            <span>
              <img
                alt={"Right Arrow Icon"}
                src={require(`../../assest/OpenLinkRightChevron.png`)}
                className="open-link-right-rotated-icon"
              />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const activeAccounts = accounts.filter(
    (account: any) => account.accountStatus === "ACTIVE"
  );

  const grossCurrentBalance = activeAccounts.reduce(
    (acc: number, curr: any) => {
      return (acc += curr.balance);
    },
    0
  );

  const accountWidgets = activeAccounts.map((account: any) => (
    <div
      key={account.accountNumber}
      className={`border rounded col-12 col-md-6 col-lg-3 m-2 py-2`}
      style={{ minWidth: "416px" }}
    >
      <div className="d-flex justify-content-between">
        <p className="fw-bold">{account.accountName}</p>
        <Link to={`/accountdetails/${account.accountNumber}`}>
          <RightArrow />
        </Link>
      </div>
      <div className={styles.darkGrayColor}>
        <span>{account.creationDate}</span>
        <span className="px-1">|</span>
        <span>{account.accountNumber}</span>
      </div>
      <div className={styles.accountBalance}>£{account.balance}</div>
      <div className={styles.darkGrayColor}>
        <span>{account.interestRate} Gross</span>
        <span className="px-1">/</span>
        <span>{account.aer} AER</span>
      </div>
      <hr />
      <DashboardButtons account={account} />
    </div>
  ));

  if (isLoading) {
    return <>Loading...</>;
  }

  return (
    <div className="m-2 p-2">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10">
            <h3 className="fw-bold text-start">{Strings.dashboardHeading}</h3>
            <p className="text-primary_one">
              {Strings.dashboardCurrentBalance}
              <span className="fw-bold px-1">
                <span>£</span>
                {grossCurrentBalance}
              </span>
            </p>
          </div>
        </div>
        <div className="row justify-content-start">
          {!error ? <>{accountWidgets}</> : <p className="ps-4">{error}</p>}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 py-2">
            <h3 className="fw-bold text-start">
              {Strings.dashboardDiscoverMore}
            </h3>
          </div>
        </div>
        <div className="row justify-content-start">
          <DiscoverMore />
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
