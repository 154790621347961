import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateValidationIsRequired } from "../../../redux/reducer/stepperSubProductSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import Strings from "../../../theme/string";
import { Button } from "../../basic components/button";
import {
  INITIAL_COUNT, STEPPER_USER_DETAILS_FIELD, VERIFY_EMPLOYEE_OTHER_JOB
} from "../../constant/customConstant";
import { Modal } from "react-bootstrap";
import { findValueInArray } from "../../modules/findValueInArray";

export const StepperFooter = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stepperDetails: any = useAppSelector((state: any) => state.stepperDetails);
  const { activeStep, setActiveStep } = props;
  const dataUserDetails = stepperDetails?.stepperUserDetails;
  const dataTaxDetails = stepperDetails?.stepperCitizenAndTax;
  const dataLinkUKBank = stepperDetails?.stepperLinkUKBank;
  const dataEmployeeDetails = stepperDetails?.stepperEmployeeDetails;
  const [isCancelModalEnabled, setIsCancelModalEnabled] = useState(false);

  const validationIsRequired = (boolVal: boolean) => {
    dispatch<any>(updateValidationIsRequired(boolVal))
  }


  const checkUserDetails = () => {
    let isTrue = STEPPER_USER_DETAILS_FIELD?.find(item => !dataUserDetails[item]?.isValidate)
    if (isTrue) {
      validationIsRequired(true)
      return false;
    }
    else {
      validationIsRequired(false);
      return true;
    }
  }

  const isTaxNumberField = (tmpVal: string) => {
    let isValidate = dataTaxDetails?.taxNumber?.find((item: any) => {
      return item.countryName == tmpVal && item.isValidate
    })
    if (isValidate) return true;
    else return false;
  }

  const checkCitizenAndTaxInformation = () => {
    const tmpIsUKCitizenOnly = dataTaxDetails?.isUkCitizenOnly?.value == Strings.yes ? dataTaxDetails?.otherThanUKCitizen?.isValidate : dataTaxDetails?.isUkCitizenOnly?.value != Strings.yes;
    const tmpTaxCheckBoxClicked = dataTaxDetails?.isTaxInputEnableForAnother?.value || dataTaxDetails?.isTaxInputEnableForUK?.value || dataTaxDetails?.isTaxInputEnableForUS?.value;
    const tmpTaxNumberIsUSGiven = !dataTaxDetails?.isTaxInputEnableForUS?.value ? true : isTaxNumberField(Strings.us);
    const tmpOtherCountryResident = !dataTaxDetails?.isTaxInputEnableForAnother?.value ? true : dataTaxDetails?.otherThanUKTaxNumber?.isValidate ? true : false;
    const tmpTaxDetailsField = !dataTaxDetails?.isTaxInputEnableForAnother?.value ? true : dataTaxDetails?.otherThanUKTaxNumber?.value.find((item: any) => isTaxNumberField(item.label))
    if (dataTaxDetails?.isUkCitizenOnly?.value != undefined && tmpIsUKCitizenOnly && tmpTaxCheckBoxClicked && tmpTaxNumberIsUSGiven && tmpOtherCountryResident && tmpTaxDetailsField) {
      validationIsRequired(false)
      return true;
    }
    else {
      validationIsRequired(true)
      return false;
    }
  }

  const checkLinkUKBankAccount = () =>{
    const tmpAccountNumber = dataLinkUKBank?.accountNumber?.isValidate;
    const tmpDepositAmount = dataLinkUKBank?.depositAmount?.isValidate;
    const tmpSortCode = dataLinkUKBank?.sortCode?.isValidate;
    const tmpInterestBeingPaid = dataLinkUKBank?.interestBeingPaid?.isValidate;
    
    if(tmpAccountNumber && tmpDepositAmount && tmpSortCode && tmpInterestBeingPaid){
      validationIsRequired(false)
      return true;
    }
    validationIsRequired(true)
    return false;
  }

  const checkEmployDetails = () =>{
    const tmpIncomeRange = dataEmployeeDetails?.incomeRange?.isValidate;
    const tmpJobRole = dataEmployeeDetails?.jobRole?.isValidate;
    const tmpEmployeeStatus = dataEmployeeDetails?.employeeStatus?.isValidate;
    const tmpJobIndustry = dataEmployeeDetails?.jobIndustry?.isValidate;

    if(findValueInArray(VERIFY_EMPLOYEE_OTHER_JOB, dataEmployeeDetails?.employeeStatus?.value) && tmpIncomeRange && tmpJobRole && tmpJobIndustry)
    {
      validationIsRequired(false)
      return true;
    }
    else if(dataEmployeeDetails?.employeeStatus?.value && !findValueInArray(VERIFY_EMPLOYEE_OTHER_JOB, dataEmployeeDetails?.employeeStatus?.value))
      {
        validationIsRequired(false)
        return true;
      }
    else {
      validationIsRequired(true)
      return false;
    }
  }

  const checkAllStepper = () => {
    switch (activeStep) {
      case Strings.stepOne:
        if (stepperDetails?.choosePlanConsent == true &&  Object.keys(stepperDetails?.stepperChoosedPlan).length != INITIAL_COUNT ) {
          validationIsRequired(false)
          setActiveStep(activeStep + 1);
          return true;
        }
          validationIsRequired(true)
          return false;
        break;

      case Strings.stepThree:
        if (checkUserDetails()) {
          setActiveStep(activeStep + 1);
          return true;
        }
          return false;
      case Strings.stepFour:
        if (checkCitizenAndTaxInformation()) {
          setActiveStep(activeStep + 1)
          return true;
        }
        return false;
      case Strings.stepFive:
        if(checkLinkUKBankAccount())
        {
          setActiveStep(activeStep + 1)
          return true;
        }
        return false;
      case Strings.stepSeven:
        if(checkEmployDetails())
        {
          setActiveStep(activeStep + 1)
          return true;
        }
        return false;
      case Strings.stepSix:
        if (stepperDetails.reviewConfirmation == true) {
          validationIsRequired(false)
          setActiveStep(activeStep + 1);
          return true;
        }
        else
          validationIsRequired(true)
        return true
        break;
      case Strings.stepTwo:
        setActiveStep(activeStep + 1)
        return true;
      case Strings.stepEight:
        navigate("/verifyCustomer")
        break;
    }
  }

  const buttonName = () => {
    switch (activeStep) {
      case Strings.stepTwo:
        return Strings.startApplication;
      case Strings.stepOne:
      case Strings.stepThree:
      case Strings.stepFour:
      case Strings.stepFive:
      case Strings.stepSix:
      case Strings.stepSeven:
        return Strings.btnContinue;
      case Strings.stepEight:
        return Strings.submitApplication;
    }
  }

  const cancelButtonModal = () => {
    //Cancel button to show modal when clicked and get user's confrimation to navigate back
    setIsCancelModalEnabled(true);
  }

  const closeApplicationAndNavigate = () => {
    //Navigate as user confirmed
    setIsCancelModalEnabled(false);
    navigate("/savings");
  }

  return (
    <div className="formButtonBlocks">
      <Button
        label={Strings.btnCancel}
        className="btn btn-secondary btn-md"
        onClick={() => cancelButtonModal()}
      />
      <Modal
        show={isCancelModalEnabled}
        size={"xl"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="customHowWeUseDataModal onboardingCancelModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{Strings.cancelRequestHeading}</Modal.Title>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setIsCancelModalEnabled(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="new-line">{Strings.cancelRequestDescription}</div>
        </Modal.Body>
        <Modal.Footer className="mb-3">
          <div>
            <Button
              label={Strings?.cancelRequestButtonText}
              className={"btn btn-md btn-primary_one"}
              onClick={() => closeApplicationAndNavigate()}
            />
          </div>
        </Modal.Footer>
      </Modal>
      <div>
        {activeStep != INITIAL_COUNT && (
          <Button
            label={Strings.btnPrevios}
            className="btn btn-md btn-primary_one-outlined me-2"
            onClick={() => setActiveStep(activeStep - 1)}
          />
        )}
        <Button
          label={buttonName()}
          className={
            "btn btn-md btn-primary_one"
          }
          onClick={checkAllStepper}
        />
      </div>
    </div>
  );
};
