import React from "react";
import VecotorTickTock from "../../../assest/vector_tick.svg";
import { Img } from "../../basic components/img";

export const StepperButton = (props: any) => {
  const { activeStep } = props;
  const buttonArrayDis = () => {
    let i = 1;
    let dis = [];
    do {
      dis.push(
        <>
        {activeStep == i-1 ? (
            <td>
              {" "}
              <div className="square-stepper-button current">{i}</div>
            </td>
          ): 
          activeStep >= i ? (
            <td>
              <div className="square-stepper-button complete">
                <Img
                  src={VecotorTickTock}
                  className="img-fluid rounded-start img-margin-top-75 stepper-img"
                  alt="Saving accounts"
                  width="auto"
                />
              </div>
            </td>
          ) 
           :  <td>
          {" "}
          <div className="square-stepper-button">{i}</div>
        </td>}
        {
            8 != i && activeStep >= i && (
              <td>
                <div className="line-stepper-button complete"></div>
              </td>
            )
          }
          {
            8 != i &&  activeStep < i && (
              <td>
                <div className="line-stepper-button"></div>
              </td>
            )
          }
        </>
      );
    }while (++i <= 8)
    return dis;
  };

  return (
    <>
      <table className="wizard-stepper">
        <tbody>
          <tr>{buttonArrayDis()}</tr>
        </tbody>
      </table>
    </>
  );
};
