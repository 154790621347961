import { ValidationInput } from "../validationInput";
import React, { useMemo } from "react";
import Strings from "../../../theme/string";
import { MIDDLE_NAME_REGEX } from "../../../common/constant/regexConstants";
import { NameFieldProps } from "../../../interfaces/reviewStepper.interface";

const NameField = ({
  stepperDetails,
  reviewFlag = false,
  fieldName,
}: NameFieldProps) => {
  const fieldData = stepperDetails.stepperUserDetails[fieldName];
  const { value, draftValue, isValidate, isdraftValidate } = fieldData || {};
  const selectedValue = reviewFlag ? draftValue : value;
  const selectedValidation = reviewFlag ? isdraftValidate : isValidate;

  const isError = useMemo(
    () => stepperDetails.buttonValidatonIsRequired && !selectedValidation,
    [stepperDetails.buttonValidatonIsRequired, selectedValidation]
  );

  const fieldMapping = useMemo(
    () => ({
      firstname: {
        label: Strings.firstName,
        placeholder: Strings.firstNamePlaceholder,
        blankErrorMsg: Strings.firstNameBlankErrorMsg,
        regExp: Strings.nameRegex,
        labelDangerRequired: true,
      },
      middlename: {
        label: Strings.middleName,
        placeholder: Strings.middleNamePlaceholder,
        blankErrorMsg: "",
        regExp: MIDDLE_NAME_REGEX,
        labelDangerRequired: false,
      },
      lastname: {
        label: Strings.lastName,
        placeholder: Strings.lastNamePlaceholder,
        blankErrorMsg: Strings.lastNameBlankErrorMsg,
        regExp: Strings.nameRegex,
        labelDangerRequired: true,
      },
    }),
    []
  );

  const { label, placeholder, blankErrorMsg, regExp, labelDangerRequired } =
    fieldMapping[fieldName];

  return (
    <ValidationInput
      lableValue={label}
      labelDangerRequired={labelDangerRequired}
      placeHolder={placeholder}
      isBottomText={true}
      bottomText={Strings.defaultNameBottomText}
      type={Strings.defaultInputType}
      errorMsg={Strings.defaultNameErrorMsg}
      regExp={regExp}
      isError={isError}
      id={fieldName}
      value={selectedValue}
      blankErrorMsg={blankErrorMsg}
      spanclassName="fst-italic font-size-12px"
      reviewFlag={reviewFlag}
      mainValue={value}
      mainValueValidation={isValidate}
    />
  );
};
export default NameField;
