export const URL_PATH = `${process.env.LOCAL_APP_BASE_URL}`;
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-product-service/v1/`;
export const COUNTRY_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/countries`;
export const LOGO = "logo";
export const NAVIGATION_BAR = "navigationBar";
export const LIFESTYLE = "lifestyles";
export const SAVING_ACCOUNT_PRODUCT = "savingAccountProduct";
export const MIDDLEPAGE = "middlePage";
export const MIDDLEPAGETOP = "middleTopPage";
export const STEPPERSUBPRODUCT = "stepperChoosePlan";
export const STEPPERSUMMARYBOXPRIVACYPOLICY = "stepperSummaryBoxPrivacyPolicy ";
export const SAVING_ACCOUNT_ALL_PRODUCTS = "saving-products";

export const MONUMENT_BASE_URL = "https://www.monument.co";
export const INDUSTRIES_URL = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-sf-integration-service/v1/proxy/industries`;
export const ALL_SAVING_ACCOUNT_SF = `${process.env.REACT_APP_BASE_URL}/techcocustapi/techco-account-service/v1/savings`;
// export const logoEcology = "logoEcology";
// export const NAVIGATION_BAR_ECOLOGY = "navigationBarEcology";

// export const NAVIGATION_BAR_MONUMENT = "navigationBarMonument";
// export const logoMonument = "logoMonument";
