import React from "react";
import Modal from "react-bootstrap/Modal";

export const EmploymentInfoModal = (props: any) => {
    const { data, onClick, show } = props;
    return (
        <Modal
            show={show}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="customHowWeUseDataModal"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{data?.title}</Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                    onClick={onClick}
                ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="new-line">{data?.description}</div>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    );
};
