import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
  AppLink,
  FooterLinks,
  NavigationLink,
  Section,
  SocialLink,
} from "../../../interfaces/footer.interface";
import { getFooterDetailsData } from "../../../services/appApi";
import { Img } from "../../basic components/img";
import Strings from "../../../theme/string";
import iconMap from "./footerIconMap";

export const CustomFooter = React.memo(() => {
  const [footerLinks, setFooterLinks] = useState<FooterLinks | null>(null);

  useEffect(() => {
    const fetchFooterLinks = async () => {
      try {
        const apiData = await getFooterDetailsData();
        if (apiData) {
          setFooterLinks(apiData);
        } else {
          console.error("Error fetching footer details!");
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    };
    fetchFooterLinks();
  }, []);

  const socialLinks = useMemo(
    () =>
      footerLinks?.socialLinks.map((link: SocialLink) => (
        <a
          key={link.name}
          href={link.url}
          aria-label={link.ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
          className="me-2"
        >
          <Img
            src={iconMap[link.icon]}
            alt={link.name}
            className="social-icon"
            loading="lazy"
          />
        </a>
      )),
    [footerLinks?.socialLinks]
  );

  const appLinks = useMemo(
    () =>
      footerLinks?.appLinks.map((link: AppLink) => (
        <a
          key={link.name}
          href={link.url}
          aria-label={link.ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
          className="mb-2"
        >
          <Img
            src={iconMap[link.icon]}
            alt={link.name}
            className="app-download-icon"
            loading="lazy"
          />
        </a>
      )),
    [footerLinks?.appLinks]
  );

  const sections = useMemo(
    () =>
      footerLinks?.sections.map((section: Section, index: number) => (
        <div key={index} className="col-3">
          <h3 className="h5 mb-4">{section.title}</h3>
          <ul className="list-unstyled">
            {section.links.map((link: NavigationLink) => (
              <li key={link.name} className="mb-2">
                <Link to={link.url} className="points">
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )),
    [footerLinks?.sections]
  );
  return (
    <footer
      id="customFooter"
      className="footer mt-5 footer text-center text-lg-start"
      aria-labelledby="footerHeading"
    >
      <div className="container-xxl">
        <div className="row row-top-padding footer-heading">
          <div className="col text-center fw-normal lh-lg font-size-48px font-family-Courgette mb-4">
            {Strings?.footerSavingsText}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <h3 className="h5">{Strings?.followusText}</h3>
            <div className="d-flex justify-content-start mb-4">
              {socialLinks}
            </div>
            <h3 className="h5">{Strings?.getTheAppText}</h3>
            <div className="d-flex flex-column mb-4">{appLinks}</div>
          </div>
          {sections}
        </div>
        <div className="d-flex flex-row custom-footer-gap">
          <div>
            <Img
              src={iconMap["FcsLogoIcon"]}
              alt="FSCS Logo"
              className="fscs-logo"
              loading="lazy"
            />
          </div>
          <div>
            <p>{footerLinks?.monumentDescription}</p>
          </div>
        </div>
      </div>
    </footer>
  );
});
