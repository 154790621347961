export const hasSufficientBalance = (balance: number) => balance > 0;

export const getAccountFundingDeadline = (creationDate: string) => {
  const creation = new Date(creationDate);
  const fundingEndDate = new Date(creation);
  fundingEndDate.setDate(creation.getDate() + 14);
  return fundingEndDate;
};

export const isFundingPeriodActive = (creationDate: string) => {
  const today = new Date();
  const fundingEndData = getAccountFundingDeadline(creationDate);
  return today <= fundingEndData;
};
