import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import welcomeBoardsSlice from "../reducer/welcomeBoardsSlice";
import customeCredentialSlice from "../reducer/customeCredentialSlice";
import lifeStyleSlice from "../reducer/lifestyleSlice";
import lodderSlice from "../reducer/lodderSlice";
import middlePageBottomSlice from "../reducer/middlePageBottomSlice";
import middlePageTopSlice from "../reducer/middlePageTopSlice";
import navigationBarSlice from "../reducer/navigationBarSlice";
import savingAccountProductSlice from "../reducer/savingAccountProductSlice";
import savingAccountPublicProductSlice from "../reducer/savingAccountPublicProductSlice";
import stepperSubProductSlice from "../reducer/stepperSubProductSlice";
import stepperSummaryBoxPrivacyPolicy from "../reducer/stepperSummaryBoxPrivacyPolicySlice";
import countryListSlice from "../reducer/countryListSlice";
import industriesListSlice from "../reducer/industriesListSlice";
import memorableWordReducer from "../reducer/memorableWordSlice";
import dashboardAccountSlice from "../reducer/dashboardAccountSlice";
import dashboardDiscoverMoreSlice from "../reducer/dashboardDiscoverMoreSlice";

let extraMiddleware: any;
if ("__DEV__") {
  extraMiddleware = logger;
} else {
  extraMiddleware = "";
}

export const store = configureStore({
  reducer: {
    lifeStyle: lifeStyleSlice,
    mainNavigation: navigationBarSlice,
    customeCredential: customeCredentialSlice,
    lodder: lodderSlice,
    savingAccountProduct: savingAccountProductSlice,
    savingAccountPublicProduct: savingAccountPublicProductSlice,
    welcomeBoards: welcomeBoardsSlice,
    middlePageBottom: middlePageBottomSlice,
    middlePageTop: middlePageTopSlice,
    stepperDetails: stepperSubProductSlice,
    stepperStepTwo: stepperSummaryBoxPrivacyPolicy,
    countryList: countryListSlice,
    industriesList: industriesListSlice,
    memorableWord: memorableWordReducer,
    dashboardAccountData: dashboardAccountSlice,
    dashboardDiscoverMore: dashboardDiscoverMoreSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(extraMiddleware),
});
