import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAccountDetails, getAccountProductPublic } from "../../services/publicApi";

const AccountDetails = (props: any) => {

    const [accountDataMambu, setAccountDataMambu] = useState();
    const [transactionHistory, setTransactionHistory] = useState();
    const [accountDataSf, setAccountDataSf] = useState();

    useEffect(() => {
        fetchAccountDetails();
    }, [])

    const fetchAccountDetails = async() => {
        let tokenFromStorage = await localStorage.getItem("tokenVal");
        console.log("tokenFromStorage=== ", tokenFromStorage);

        let urlFromStorage = await localStorage.getItem("urlVal");
        console.log("urlFromStorage=== ", urlFromStorage);

        if(tokenFromStorage == null){
            localStorage.setItem('tokenVal', 'sampleData')
        }

        if(urlFromStorage == null){
            localStorage.setItem('urlVal', 'https://sampleURL')
        }

        let actualToken = await localStorage.getItem("tokenVal");
        console.log("actualToken=== ", actualToken);

        let actualUrl = await localStorage.getItem("urlVal");
        console.log("actualUrl=== ", actualUrl);

        const myHeaders = new Headers();
        let TOKEN = actualToken;  
        let URL = actualUrl;
        const res = await getAccountDetails(URL, TOKEN);
        console.log("VERIFICATION of API on account details::: ", res);
    };

    return (
        <>
            Account Details Screen
        </>
    )
}

export default AccountDetails;
