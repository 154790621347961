import React from "react";

export const RadioButton = (props : any) => {
  const {
    id,
    inputClassName,
    labelClassName = "",
    onChange,
    name,
    isChecked,
    isDisabled,
    value,
    label,
  } = props;
  return (
    <div className="form-check customradio">
      <input
        type="radio"
        className={`form-check-input ${inputClassName}`}
        id={id}
        data-testid={id}
        onChange={onChange}
        name={name}
        checked={isChecked}
        disabled={isDisabled}
        value={value}
      />
      <label htmlFor={id} className={`form-check-label ${labelClassName} checkBoxLabel`}>
        {label}
      </label>
    </div>
  );
};