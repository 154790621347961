import React, { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CustomFooter } from "./common/components/footer/customFooter";
import ErrorFallback from "./FallBackRender";
import { ThemeContext } from "./Theme";
import { HomeScreen } from "./screens/home/homeScreen";
import { SavingScreen } from "./screens/savingsAccountType/savingScreen";
import { LifeStyleScreen } from "./screens/lifeStyleScreen";
import { AboutUsScreen } from "./screens/aboutUs/aboutUsScreen";
import LoginScreen from "./screens/login/loginScreen";
import RegistrationScreen from "./screens/registrationScreen";
import { Header } from "./common/components/header/header";
import { fetchData } from "./services/fetchContentfullData";
import { Stepper } from "./common/components/stepper/stepper";
import { MiddleTopPage } from "./common/components/middleTopPage";
import ScrollToTop from "./common/modules/scrollToTop";
import MemorableWord from "./screens/memorableWord/memorableWord";
import DashboardComponent from "./screens/dashboard/dashboardComponent";
import VerifyCustomerDetails from "./screens/submissionAndVerification/verifyCustomerDetails";
import AccountDetails from "./screens/dashboard/accountDetails";

export const App = () => {
  const { theme } = useContext(ThemeContext);
  useEffect(()=>{
    fetchData();
  },[])
  
  return (
    <BrowserRouter>
      <React.StrictMode>
        <div className={`App ${theme} container-fluid p-0`}>
          <Header />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ScrollToTop>
              <Routes>
                <Route path={"/"} element={<HomeScreen />} />
                <Route path={"/home"} element={<HomeScreen />} />  
                <Route path={"/mainProduct"} element={<MiddleTopPage />}/>
                <Route path={"/product"} element={<HomeScreen />} />
                <Route path={"/savings"} element={<SavingScreen />} />
                <Route path={"/onBoardingStart/:id"} element={<Stepper />} />
                <Route path={"/lifeStyle"} element={<LifeStyleScreen />} />
                <Route path={"/about_us"} element={<AboutUsScreen/>} />
                <Route path={"/login"} element={<LoginScreen />} />
                <Route path={"/registration"} element={<RegistrationScreen />} />
                <Route path={"/dashboard"} element={<DashboardComponent />} />
                <Route path={"/verifyCustomer"} element={<VerifyCustomerDetails />} />
                <Route path={"/memorableWord"} element={<MemorableWord />} />
                <Route path={"/accountdetails"} element={<AccountDetails />} />
                <Route path={"/accountdetails/:accountNumber"} element={<AccountDetails />} />
              </Routes>
            </ScrollToTop>
          </ErrorBoundary>
          <CustomFooter />
        </div>
      </React.StrictMode>
    </BrowserRouter>
  );
};


// theme -> style 
